import { apiUrl } from "@/env";
import userRepository from "@/repository/user.ts";
import activityRepository from "@/repository/activity.ts";
import { corsHeaders, formDataHeaders, jsonHeaders } from "@/repository/deps";

const repository = {
  user: userRepository,
  activity: activityRepository,
  /**
   * Authenticate user to the API
   * @param credentials
   */
  getToken: (credentials: {
    username: string;
    password: string;
  }): Promise<any> => {
    return fetch(`${apiUrl}/token`, {
      method: "POST",
      headers: { ...formDataHeaders, ...corsHeaders },
      body: new URLSearchParams(credentials),
      mode: "cors",
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  },
  /**
   * Request for API registration email to be sent at email
   * @param email
   */
  getRegistrationEmail: (email: string): Promise<any> => {
    return fetch(`${apiUrl}/registration`, {
      method: "POST",
      body: JSON.stringify({ email: email }),
      headers: { ...jsonHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  },
  getTokenFromQuickCreds: (
    credentials: {
      email: string;
      auth_uuid: string;
    },
    sequenceCode: string
  ): Promise<any> => {
    return fetch(`${apiUrl}/token/${sequenceCode}`, {
      method: "POST",
      headers: { ...jsonHeaders, ...corsHeaders },
      body: JSON.stringify(credentials),
      mode: "cors",
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  },
  getRenewedToken: (renewalToken: string): Promise<any> => {
    return fetch(`${apiUrl}/token/renew?refresh=${renewalToken}`, {
      method: "GET",
      headers: { ...corsHeaders },
      mode: "cors",
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  },
};

export default repository;
