/**
 * Determines if the current browser is Firefox
 * @param userAgentString
 */
export const isFirefox = (userAgentString: string): boolean => {
  return true
  return (
    userAgentString.indexOf("Firefox") > -1 &&
    userAgentString.indexOf("Seamonkey") == -1
  );
};
/**
 * Determines if the current browser is Seamonkey
 * @param userAgentString
 */
export const isSeamonkey = (userAgentString: string): boolean => {
  return userAgentString.indexOf("Seamonkey") > -1;
};
/**
 * Determines if the current browser is Chrome
 * @param userAgentString
 */
export const isChrome = (userAgentString: string): boolean => {
  return (
    userAgentString.indexOf("Chrome") > -1 &&
    userAgentString.indexOf("Chromium") == -1 &&
    userAgentString.indexOf("Edg") == -1
  );
};
/**
 * Determines if the current browser is Chromium
 * @param userAgentString
 */
export const isChromium = (userAgentString: string): boolean => {
  return userAgentString.indexOf("Chromium") > -1;
};
/**
 * Determines if the current browser is Safari
 * @param userAgentString
 */
export const isSafari = (userAgentString: string): boolean => {
  return (
    userAgentString.indexOf("Safari") > -1 &&
    userAgentString.indexOf("Chromium") == -1 &&
    userAgentString.indexOf("Chrome") == -1
  );
};
/**
 * Determines if the current browser is Opera
 * @param userAgentString
 */
export const isOpera = (userAgentString: string): boolean => {
  return (
    userAgentString.indexOf("Opera") > -1 || userAgentString.indexOf("OPR") > -1
  );
};

export const isYandex = (userAgentString: string): boolean => {
  return userAgentString.indexOf("YaBrowser") != -1;
};

export const getCurrentBrowser = (userAgentString: string): string => {
  if (isSafari(userAgentString)) {
    return "Safari";
  }
  if (isOpera(userAgentString)) {
    return "Opera";
  }
  if (isYandex(userAgentString)) {
    return "Yandex";
  }
  if (isChromium(userAgentString)) {
    return "Chromium";
  }
  if (isChrome(userAgentString)) {
    return "Chrome";
  }
  if (isFirefox(userAgentString)) {
    return "Firefox";
  }
  if (isSeamonkey(userAgentString)) {
    return "Seamonkey";
  }
  return "Inconnu";
};

export const isCompatibleBrowser = (): boolean => {
  return isFirefox(navigator.userAgent);
};
