import { RoomAssignmentState } from "./state";
import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";

export const state: RoomAssignmentState = {
  rooms: [],
  subjects: [],
  payoff: {},
  constraints: [],
  assignment: {},
};

const roomAssignment = {
  namespaced: true,
  state: { ...state },
  mutations,
  actions,
  getters,
};

export default roomAssignment;
