import { ActionContext } from "vuex";
import settingRepository from "@/repository/setting";
import { responseErrorHandler } from "@/utils";
import { ISetting } from "@/schemas/ISetting";

const actions = {
  /**
   * Retrieve setting identified by payload.settingId
   * @param context
   * @param payload
   */
  fetchSetting: (
    context: ActionContext<string, any>,
    payload: { settingId: string; next?: CallableFunction }
  ): void => {
    settingRepository
      .getFullById(context.rootState.token, payload.settingId)
      .then((setting) => {
        if (payload.next) {
          payload.next(setting);
        }
      })
      .catch((error) => {
        responseErrorHandler(error, context);
      });
  },
  /**
   * Retrieves all settings
   * @param context
   * @param payload
   */
  fetchSettings: (
    context: ActionContext<any, any>,
    payload: Record<string, any>
  ): void => {
    settingRepository
      .getAll(context.rootState.token)
      .then((settings) => {
        context.commit("SET_SETTINGS", settings);
        if (payload.callback) {
          payload.callback(true);
        }
      })
      .catch((error) => {
        responseErrorHandler(error, context);
      });
  },
  /**
   * Delete setting identified by payload.settingId
   * @param {ActionContext<any, any>} context
   * @param {Record<string, any>} payload
   */
  deleteSetting: (
    context: ActionContext<any, any>,
    payload: { id: string; next?: CallableFunction }
  ): void => {
    settingRepository
      .delete(context.rootState.token, payload.id)
      .then((deleted: boolean) => {
        if (deleted) {
          context.commit("REMOVE_SETTING", payload.id);
        }
      })
      .catch((error) => {
        responseErrorHandler(error, context);
      });
  },
  /**
   * Create a setting
   * @param {ActionContext<any, any>} context
   * @param {Record<string, any>} payload
   */
  createSetting: (
    context: ActionContext<any, any>,
    payload: { setting: ISetting }
  ): void => {
    settingRepository
      .create(context.rootState.token, payload.setting)
      .then((setting: ISetting) => {
        context.commit("ADD_SETTING", setting);
      })
      .catch((error) => {
        responseErrorHandler(error, context);
      });
  },
  /**
   *
   * @param context
   * @param payload
   */
  updateSetting: (
    context: ActionContext<any, any>,
    payload: { setting: ISetting; id: string; next?: CallableFunction }
  ): void => {
    if(payload.setting.group_tips_id== ''){
      delete payload.setting.group_tips_id
    }
    if(payload.setting.indiv_tips_id== ''){
      delete payload.setting.indiv_tips_id
    }
    if(payload.setting.overview_tips_id== ''){
      delete payload.setting.overview_tips_id
    }
    settingRepository
      .update(context.rootState.token, payload.id, payload.setting)
      .then((setting: ISetting) => {
        context.commit("ADD_SETTING", setting);
        if (payload.next) {
          payload.next(setting);
        }
      })
      .catch((error) => {
        responseErrorHandler(error, context);
      });
  },
  /**
   * Creates multiple settings
   * @param context
   * @param settings
   */
  createSettings: (
    context: ActionContext<any, any>,
    settings: ISetting[]
  ): void => {
    for(let i=0; i < settings.length; i++){
      if(settings[i].group_tips_id== ''){
        delete settings[i].group_tips_id
      }
      if(settings[i].indiv_tips_id== ''){
        delete settings[i].indiv_tips_id
      }
      if(settings[i].overview_tips_id== ''){
        delete settings[i].overview_tips_id
      }
    }
    settingRepository
      .createMultiple(context.rootState.token, settings)
      .then((settings) => {
        if (settings.length) {
          context.commit("SET_SETTINGS", [
            ...context.state.settings,
            ...settings,
          ]);
        }
      })
      .catch((error) => {
        responseErrorHandler(error, context);
      });
  },
  /**
   *
   * @param context
   * @param payload
   */
  fetchSettingScores: (
    context: ActionContext<any, any>,
    payload: { settingId: string; next?: CallableFunction }
  ): void => {
    settingRepository
      .getSettingScores(context.rootState.token, payload.settingId)
      .then(
        (
          scores: {
            session_id: string;
            group_score: number;
            individual_scores: { user: string; score: number }[];
          }[]
        ) => {
          if (payload.next) {
            payload.next(scores);
          }
        }
      )
      .catch((error) => {
        responseErrorHandler(error, context);
      });
  },
  fetchSettingScoreMax: (
    context: ActionContext<any, any>,
    payload: { settingId: string; next?: CallableFunction }
  ): void => {
    settingRepository
      .getSettingScoreMax(context.rootState.token, payload.settingId)
      .then((scoreMax) =>{
          if (payload.next) {
            payload.next(scoreMax);
          }
        }
      )
      .catch((error) => {
        responseErrorHandler(error, context);
      });
  },
  /**
   *
   * @param context
   * @param payload
   */
  fetchSettingSessions: (
    context: ActionContext<any, any>,
    payload: { settingId: string; next?: CallableFunction }
  ): void => {
    settingRepository
      .getSettingSessions(context.rootState.token, payload.settingId)
      .then((groups) => {
        if (payload.next) {
          payload.next(groups);
        }
      })
      .catch((error) => {
        console.log("action fetchSettingSessions: ", error);
        responseErrorHandler(error, context);
      });
  },

  fetchSettingFeebacks: (
    context: ActionContext<any, any>,
    payload: { settingId: string; next?: CallableFunction }
  ): void => {
    settingRepository
      .getSettingSessions(context.rootState.token, payload.settingId)
      .then((groups) => {
        if (payload.next) {
          payload.next(groups);
        }
      })
      .catch((error) => {
        console.log("action fetchSettingFeebacks: ", error);
        responseErrorHandler(error, context);
      });
  },
};
export default actions;
