import { RoomAssignmentState } from "./state";
import Vue from "vue";
const mutations = {
  ASSIGN_SUBJECT_TO_ROOM(
    state: RoomAssignmentState,
    payload: { room: number; subject: string }
  ): void {
    if (!state.assignment[payload.room]) {
      Vue.set(state.assignment, payload.room, [payload.subject]);
    } else {
      state.assignment[payload.room].push(payload.subject);
    }
  },
  ADD_SUBJECT_TO_DECK(state: RoomAssignmentState, subject: string): void {
    if (!state.subjects) {
      state.subjects = [subject];
    } else {
      state.subjects.push(subject);
    }
  },
  REMOVE_SUBJECT_FROM_ROOM(
    state: RoomAssignmentState,
    payload: { room: number; subject: string; index: number }
  ): void {
    state.assignment[payload.room].splice(payload.index, 1);
  },
  SET_ASSIGNED_SUBJECTS(
    state: RoomAssignmentState,
    payload: { room: number; subjects: string[] }
  ): void {
    state.assignment[payload.room] = payload.subjects;
  },
  SET_ALL_ASSIGNMENT(
    state: RoomAssignmentState,
    assignment: Record<number, string[]>
  ): void {
    state.assignment = assignment;
  },
  SET_ROOMS(state: RoomAssignmentState, rooms: []): void {
    state.rooms = rooms;
  },
  SET_CONSTRAINTS(state: RoomAssignmentState, constraints: []): void {
    state.constraints = constraints;
  },
  SET_PAYOFF(
    state: RoomAssignmentState,
    payoff: Record<string, Record<number, number>>
  ): void {
    state.payoff = payoff;
  },
  SET_SUBJECTS(state: RoomAssignmentState, subjects: []): void {
    state.subjects = subjects;
  },
};

export default mutations;
