import { apiUrl } from "@/env";
import { authHeader, corsHeaders, jsonHeaders } from "@/repository/deps";
import { BaseRepository } from "@/repository/base";
import { ISession } from "@/schemas/ISession";
import { IUserSessions } from "@/schemas/IUserSessions";
import { IResult } from "@/schemas/IResult";

class SessionRepository extends BaseRepository<ISession> {
  getSessionStatus(
    token: string,
    sessionId: string,
    channel: string
  ): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/${sessionId}/status/${channel}`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  disconnectFromSession(
    token: string,
    sessionId: string,
    channel: string
  ): Promise<any> {
    return fetch(
      `${apiUrl}/${this.basePath}/${sessionId}/disconnect/${channel}`,
      {
        method: "GET",
        headers: { ...authHeader(token), ...corsHeaders },
      }
    ).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  connectToSession(
    token: string,
    sessionId: string,
    channel: string
  ): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/${sessionId}/connect/${channel}`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

    submitSessionResult(
    token: string,
    result: IResult,
    type: string
  ): Promise<any> {
    return fetch(`${apiUrl}/results/${type}`, {
      method: "POST",
      headers: { ...authHeader(token), ...corsHeaders, ...jsonHeaders },
      body: JSON.stringify(result),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.status.toString());
    });
  }
  /**
   * Create ranking associated to result identified by resultId
   * @param token
   * @param ranking
   * @param resultId
   */
  submitRanking(
    token: string,
    ranking: Record<string, any>,
    resultId: string
  ): Promise<any> {
    return fetch(`${apiUrl}/results/ranking/${resultId}`, {
      method: "POST",
      headers: { ...authHeader(token), ...corsHeaders, ...jsonHeaders },
      body: JSON.stringify(ranking),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }
  /**
   * Retrieves cached ranking lists for drag and drop sessions
   * @param token
   * @param sessionId
   */
  getCachedRanking(token: string, sessionId: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/${sessionId}/cached/ranking`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }
  getUserWhoAgreedOnSessionEnd(token: string, sessionId: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/${sessionId}/end/users`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }
  getSessionScore(token: string, sessionId: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/${sessionId}/scores`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }
  getSessionParticipation(token: string, sessionId: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/${sessionId}/participation`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  getSessionWordNumber(token: string, sessionId: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/${sessionId}/wordnumber`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }


  addUserToSession(
    token: string,
    sessionId: string,
    userSessions: IUserSessions[]
  ): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/${sessionId}/users`, {
      method: "POST",
      headers: { ...authHeader(token), ...corsHeaders, ...jsonHeaders },
      body: JSON.stringify(userSessions),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }
  getCachedAssignment(token: string, sessionId: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/${sessionId}/cached/assignment`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }
  getSessionUsers(token: string, sessionId: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/${sessionId}/users`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }
  getSessionResults(token: string, sessionId: string): Promise<any> {
    return fetch(`${apiUrl}/results/${sessionId}/results`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

}

const sessionRepository = new SessionRepository("sessions");

export default sessionRepository;
