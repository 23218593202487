import { SessionState } from "@/store/modules/session/state";
import getters from "@/store/modules/session/getters";
import mutations from "@/store/modules/session/mutations";
import actions from "@/store/modules/session/actions";
import { SessionState as SessionStateEnum } from "@/schemas/Enums";


//SessionState remove any
const state: SessionState = {
  session: {
    status: SessionStateEnum.Created,
    results: [],
    messages: [],
  },
  sessions: [],
  users: [],

  itemsToBeRanked: [],
  rankedItems: {},
  sessionScores: [],

  correlationId: 0,
};

const session = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default session;
