import { SessionState } from "@/store/modules/session/state";
import { IMessage } from "@/schemas/ISession";

const getters = {
  getMessages: (state: SessionState): IMessage[] => {
    return state.session.messages || [];
  },
  getSingleSessionTime: (state: SessionState): number => {
    return state.session.setting?.single_session_time || NaN;
  },
  getGroupSessionTime: (state: SessionState): number => {
    return state.session.setting?.group_session_time || NaN;
  },
  getIndividualScores: (
    state: SessionState
  ): { score: number; user: string }[] => {
    if (state.sessionScores) {
      return state.sessionScores
        .filter((score) => score.scope === "individual")
        .map((result: Record<string, any>) => ({
          score: result.score,
          user: result.user_id,
        }));
    }
    return [];
  },
  getGroupScore: (state: SessionState): number => {
    return (
      state.sessionScores?.find((score) => score.scope === "group")?.score || 0
    );
  },
  getActiveUsers: (state: SessionState): Record<string, any>[] => {
    const users: Record<string, any>[] = [];
    state.session.results?.forEach((result: Record<string, any>) => {
      if (result.user) {
        users.push(result.user);
      }
    });
    return users;
  },
};

export default getters;

export const fillRanking = (
  ranking: Record<string, any>[],
  requiredLength = 15
): Record<string, any>[] => {
  if (ranking.length >= requiredLength) {
    return ranking;
  }
  const filledRanking = [];
  for (let i = 1; i <= requiredLength; i++) {
    const rankedItem = ranking.find((item) => item.actual_rank === i);
    if (rankedItem) {
      filledRanking.push(rankedItem);
    } else {
      filledRanking.push({});
    }
  }
  return filledRanking;
};

export const getFilledRanking = (
  state: SessionState,
  results: Record<string, any>[]
): Record<string, any>[] => {
  const requiredLength = state.session?.activity?.items?.length;
  if (requiredLength) {
    const filledResults = JSON.parse(JSON.stringify(results));
    filledResults.map((result: Record<string, any>) => {
      result.ranking.map((ranked_item: Record<string, any>) => {
        ranked_item.label = state.session.activity?.items?.find(
          (el: Record<string, any>) => el.id === ranked_item.item_id
        )?.label;
      });
      result.ranking = fillRanking([...result.ranking], requiredLength);
    });
    return filledResults;
  }
  return results;
};
