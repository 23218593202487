import { SequenceState } from "@/store/modules/sequence/state";
import { ISequence } from "@/schemas/ISequence";
import Vue from "vue";

const mutations = {
  SET_SEQUENCE: (state: SequenceState, payload: ISequence): void => {
    state.sequence = payload;
  },
  SET_SEQUENCES: (state: SequenceState, sessions: ISequence[]): void => {
    state.sequences = sessions;
  },
  ADD_SEQUENCE: (state: SequenceState, sequence: ISequence): void => {
    const sequences = [...state.sequences];
    sequences.push(sequence);
    Vue.set(state, "sequences", sequences);
  },
  REMOVE_SEQUENCE(state: SequenceState, index: number): void {
    state.sequences.splice(index, 1);
  },
  UPDATE_SEQUENCE(
    state: SequenceState,
    payload: { index: number; sequence: ISequence }
  ): void {
    Object.assign(state.sequences[payload.index], payload.sequence);
  },
};

export default mutations;
