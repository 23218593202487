import { statusMessage } from "@/utils/httpUtils";
import reusableNotifications from "@/utils/reusableNotifications";
import { ActionContext } from "vuex";
import clientCache from "@/utils/cacheUtils";
import { ISlide } from "@/schemas/ISlide";
import { ISlideElement } from "@/schemas/ISlideElement";
import slideRepository from "@/repository/slide";

export const parseJWT = (
  token: string
): { sub: string; exp: number } | null => {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
};

export const responseErrorHandler = (
  error: Error,
  context: ActionContext<string, any>
): void => {
  if (error.message === statusMessage["403"]) {
    window.localStorage.clear();
    context.commit("CLEAR_STATE", null, { root: true });
    context.commit(
      "SET_NOTIFICATIONS",
      [reusableNotifications.idleSession(error.message)],
      { root: true }
    );
  } else if (
    error.message === statusMessage["500"] ||
    error.message === "NetworkError when attempting to fetch resource."
  ) {
    context.commit(
      "SET_SNACK",
      {
        displaySnack: true,
        snackText:
          "Le serveur a rencontré un problème. Veuillez contacter l'administrateur.",
        snackColor: "orange",
        snackClosable: true,
      },
      { root: true }
    );
  } else {
    console.log(error);
  }
};

export function capitalizeFirst(str: string): string {
  return str ? `${str.charAt(0).toUpperCase()}${str.slice(1)}` : "";
}

export const findFirstGap = (numberArray: number[]): number => {
  let i = 1;
  for (i; i < numberArray.length; i++) {
    if (i !== numberArray[i - 1]) return i;
  }
  return i === numberArray[numberArray.length - 1] ? i + 1 : i;
};

export const clearIndividualSession = (): void => {
  clientCache.clear("ranked_items");
  clientCache.clear("items_to_be_ranked");
};

export const duplicateSlideImages = (slides: ISlide[]): void => {
  slides.forEach((slide: ISlide) => {
    slide.elements.forEach((el: ISlideElement) => {
      if (el.type === "img") {
        slideRepository.duplicateImage(
          clientCache.get("eccipe:token"),
          slide.id || "",
          el.content
        );
      }
    });
  });
};

export const generateRandomString = (length: number): string => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const randomColor = (index: number): string => {
  const colors = [
    "cyan",
    "lime",
    "pink",
    "green",
    "amber",
    "indigo",
    "blue",
    "red",
    "teal",
    "light-green",
    "purple",
    "yellow",
    "orange",
    "deep-orange",
    "brown",
    "grey",
  ];

  // return colors[Math.floor(Math.random() * colors.length)];
  return colors[index];
};

export const alphabeticalSort = (a: string, b: string): number => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};
