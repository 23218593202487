import { ActionContext } from "vuex";
import { responseErrorHandler } from "@/utils";
import messageRepository from "@/repository/message";

interface ChatroomState {
  chatroom: Record<string, any>;
  messages: Array<Record<string, any>>;
}

const state: ChatroomState = {
  chatroom: {},
  messages: [],
};

const chatroom = {
  namespaced: true,
  state,
  getters: {},
  mutations: {
    /**
     * Set state.chatroom with chatroom value
     * @param {ChatroomState} state
     * @param chatroom
     */
    SET_CHATROOM(state: ChatroomState, chatroom: Record<string, any>): void {
      state.chatroom = chatroom;
    },
    /**
     * Set state.messages with messages value
     * @param {ChatroomState} state
     * @param {Array<Record<string, any>>} messages
     */
    SET_MESSAGES(
      state: ChatroomState,
      messages: Array<Record<string, any>>
    ): void {
      state.messages = messages;
    },
    /**
     * Remove message identified by messageId from state.messages
     * @param {ChatroomState} state
     * @param {string} messageId
     */
    REMOVE_MESSAGE(state: ChatroomState, messageId: number): void {
      state.messages.splice(messageId, 1);
    },
    /**
     * Add message to state.messages
     * @param {ChatroomState} state
     * @param message
     */
    ADD_MESSAGE(state: ChatroomState, message: Record<string, any>): void {
      state.messages.push(message);
    },
    /**
     * Set the messages of state.messages at payload.index by payload.chatroom
     * @param {ChatroomState} state
     * @param {Record<string, any>} payload
     */
    UPDATE_MESSAGE(state: ChatroomState, payload: Record<string, any>): void {
      Object.assign(state.messages[payload.index], payload.message);
    },
  },
  actions: {
    /**
     * Fetch messages
     * @param {ActionContext<any, any>} context
     * @param chatroomId
     */
    fetchMessages: (
      context: ActionContext<any, any>,
      chatroomId: string
    ): void => {
      messageRepository
        .getMessages(context.rootState.token, chatroomId)
        .then((messages) => {
          context.commit("SET_MESSAGES", messages);
        })
        .catch((error) => responseErrorHandler(error, context));
    },
  },
};
export default chatroom;
