import { apiUrl } from "@/env";
import { authHeader, corsHeaders, jsonHeaders } from "@/repository/deps";

export class BaseRepository<E> {
  basePath: string;

  constructor(basePath: string) {
    this.basePath = basePath;
  }

  /**
   * Retrieves every instance of E
   * @param token
   * @param type
   */
  getAll(token: string, type?: string): Promise<any> {
    const resource: string = type
      ? `${apiUrl}/${this.basePath}/${type}`
      : `${apiUrl}/${this.basePath}`;
    return fetch(resource, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  /**
   * Retrieves an instance of E identified by id
   * @param token
   * @param id
   * @param type
   */
  getById(token: string, id: string, type?: string): Promise<any> {
    const resource: string = type
      ? `${apiUrl}/${this.basePath}/${type}/${id}`
      : `${apiUrl}/${this.basePath}/${id}`;
    return fetch(resource, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }



  /**
   * Retrieves a full instance of E identified by id
   * @param token
   * @param id
   * @param type
   */
  getFullById(token: string, id: string, type?: string): Promise<any> {
    const resource: string = type
      ? `${apiUrl}/${this.basePath}/${type}/${id}/full`
      : `${apiUrl}/${this.basePath}/${id}/full`;
    return fetch(resource, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  /**
   * Creates an instance of E
   * @param token
   * @param toCreate
   * @param type
   */
  create(token: string, toCreate: E, type?: string): Promise<any> {
    const resource: string = type
      ? `${apiUrl}/${this.basePath}/${type}`
      : `${apiUrl}/${this.basePath}`;
    console.log(toCreate)
    return fetch(resource, {
      method: "POST",
      headers: { ...authHeader(token), ...corsHeaders, ...jsonHeaders },
      body: JSON.stringify(toCreate),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  /**
   * Creates multiple instances of E
   * @param token
   * @param toCreate
   * @param type
   */
  createMultiple(token: string, toCreate: E[], type?: string): Promise<any> {
    const resource: string = type
      ? `${apiUrl}/${this.basePath}/${type}/multiple`
      : `${apiUrl}/${this.basePath}/multiple`;


    console.log(JSON.stringify(toCreate))
    return fetch(resource, {
      method: "POST",
      headers: { ...authHeader(token), ...corsHeaders, ...jsonHeaders },
      body: JSON.stringify(toCreate),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  /**
   * Updates an instance of E identified by id
   * @param token
   * @param id
   * @param toUpdate
   * @param type
   */
  update(token: string, id: string, toUpdate: E, type?: string): Promise<any> {
    const resource: string = type
      ? `${apiUrl}/${this.basePath}/${type}/${id}`
      : `${apiUrl}/${this.basePath}/${id}`;

    return fetch(resource, {
      method: "PATCH",
      headers: { ...authHeader(token), ...corsHeaders, ...jsonHeaders },
      body: JSON.stringify(toUpdate),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  /**
   * Deletes an instance of E identified by id
   * @param token
   * @param id
   * @param type
   */
  delete(token: string, id: string, type?: string): Promise<any> {
    const resource: string = type
      ? `${apiUrl}/${this.basePath}/${type}/${id}`
      : `${apiUrl}/${this.basePath}/${id}`;
    return fetch(resource, {
      method: "DELETE",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return true;
      }
      throw new Error(response.statusText);
    });
  }
}
