import { RoomAssignmentState } from "@/store/modules/activities/roomAssignment/state";

const getters = {
  getRoomAssignmentById:
    (state: RoomAssignmentState) =>
    (id: number): string[] => {
      return state.assignment[id];
    },
};
export default getters;
