import { apiUrl } from "@/env";

/**
 * Returns required HTTP headers for API authentication
 * @param token
 */
export const authHeader = (token: string): { Authorization: string } => {
  return {
    Authorization: `Bearer ${token}`,
  };
};

/**
 * Required HTTP headers for JSON body submitting
 */
export const jsonHeaders = {
  accept: "application/json",
  "Content-Type": "application/json",
};

/**
 * Required HTTP headers for CORS handling
 */
export const corsHeaders = { "Access-Control-Allow-Origin": apiUrl };

/**
 * Required HTTP headers for form data body submitting
 */
export const formDataHeaders = {
  "Content-Type": "application/x-www-form-urlencoded",
};
