import { BaseRepository } from "@/repository/base";
import { ISequence } from "@/schemas/ISequence";
import { apiUrl } from "@/env";
import { authHeader, corsHeaders, jsonHeaders } from "@/repository/deps";
import { SessionState } from "@/schemas/Enums";

class SequenceRepository extends BaseRepository<ISequence> {
  getRequested(token: string, sequenceCode: string): Promise<any> {
    return fetch(`${apiUrl}/sequences/requests/${sequenceCode}`, {
      method: "GET",
      headers: { ...jsonHeaders, ...authHeader(token) },
    }).then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  requestToJoin(
    sequenceCode: string,
    payload: {
      code: string;
      username: string;
      otp: string;
      email: string;
      registered: boolean;
    }
  ): Promise<any> {
    return fetch(`${apiUrl}/sequences/requests/${sequenceCode}`, {
      method: "POST",
      headers: { ...jsonHeaders },
      body: JSON.stringify(payload),
    }).then((response: Response) => {
      if (response.ok) {
        return response.text();
      }
      //TODO need to access header and check the reason
      console.log(response.body);
      console.log(response.headers.get("x-error"));
      throw new Error(
        JSON.stringify({
          status: response.statusText,
          details: JSON.stringify(response.headers),
        })
      );
    });
  }

  getSequenceUsers(
    sequenceCode: string,
    payload: {
      code: string;
      username: string;
      otp: string;
      email: string;
      registered: boolean;
    }
  ): Promise<any> {
    return fetch(`${apiUrl}/sequences/requests/approved/${sequenceCode}`, {
      method: "GET",
      headers: { ...jsonHeaders },
      body: JSON.stringify(payload),
    }).then((response: Response) => {
      if (response.ok) {
        return response.text();
      }
      //TODO need to access header and check the reason
      console.log(response.body);
      console.log(response.headers.get("x-error"));
      throw new Error(
        JSON.stringify({
          status: response.statusText,
          details: JSON.stringify(response.headers),
        })
      );
    });
  }

  denyRequest(
    token: string,
    sequenceCode: string,
    denied: string
  ): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/requests/${sequenceCode}`, {
      method: "DELETE",
      headers: { ...jsonHeaders, ...authHeader(token) },
      body: denied,
    }).then((response: Response) => {
      if (response.ok) {
        return response.text();
      }
      throw new Error(response.statusText);
    });
  }

  approveRequests(token: string, sequenceCode: string): Promise<any> {
    return fetch(
      `${apiUrl}/${this.basePath}/requests/approved/${sequenceCode}`,
      {
        method: "POST",
        headers: { ...authHeader(token) },
      }
    ).then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  getApprovedUsers(token: string, sequenceCode: string): Promise<any> {
    return fetch(
      `${apiUrl}/${this.basePath}/requests/approved/${sequenceCode}`,
      {
        method: "GET",
        headers: { ...authHeader(token) },
      }
    ).then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  setRequestingUser(code: string, token: string, usersList: []): Promise<any> {

    return fetch(`${apiUrl}/${this.basePath}/requests/setuser/${code}`, {
      method: "POST",
      headers: { ...jsonHeaders, ...corsHeaders, ...authHeader(token) },
      body: JSON.stringify(usersList),
    }).then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  getRequestingUsers(sequenceCode: string): Promise<any> {
    return fetch(
      `${apiUrl}/${this.basePath}/requests/requesting/${sequenceCode}`,
      {
        method: "GET",
        }
    ).then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  getFullByCode(token: string, code: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/code/${code}/full`, {
      method: "GET",
      headers: { ...authHeader(token) },
    }).then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  getUserSequenceById(token: string, id: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/${id}/users`, {
      method: "GET",
      headers: { ...authHeader(token) },
    }).then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  getByCode(token: string, code: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/code/${code}`, {
      method: "GET",
      headers: { ...authHeader(token) },
    }).then((response: Response) => {
      if (response.ok) {
        return true;
      }
      throw new Error(response.statusText);
    });
  }

  getOTP(email: string, code: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/otp/${code}`, {
      method: "POST",
      headers: { ...jsonHeaders, ...corsHeaders },
      body: JSON.stringify({ email }),
    }).then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      if (response.status !== 202) {
        throw new Error(response.statusText);
      }
    });
  }

  getMySequences(token: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/me`, {
      method: "GET",
      headers: { ...corsHeaders, ...authHeader(token) },
    }).then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  getSequenceGroups(token: string, id: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/${id}/groups`, {
      method: "GET",
      headers: { ...corsHeaders, ...authHeader(token) },
    }).then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  getSequenceSessions(token: string, sequence_id: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/${sequence_id}/sessions`, {
      method: "GET",
      headers: { ...corsHeaders, ...authHeader(token) },
    }).then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  getMyStatus(code: string, userCode: string, email: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/status/${code}/${userCode}`, {
      method: "POST",
      headers: { ...jsonHeaders, ...corsHeaders },
      body: JSON.stringify({ email: email }),
    }).then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  checkOTP(
    sequenceCode: string,
    payload: { otp: string; email: string }
  ): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/otp/${sequenceCode}/check`, {
      method: "POST",
      headers: { ...jsonHeaders, ...corsHeaders },
      body: JSON.stringify(payload),
    }).then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  getSequenceByStatus(token: string, state: SessionState): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/status/${state}`, {
      method: "GET",
      headers: { ...corsHeaders, ...authHeader(token) },
    }).then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  getSessionMove(token: string, settingId: string, sequenceId:string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/getmoves/${sequenceId}/${settingId}`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      console.log("coucou")

      if (response.ok) {

        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  getMyQuickAuthId(sequenceCode: string, email: string): Promise<any> {
    return fetch(`${apiUrl}/${this.basePath}/quick_auth/${sequenceCode}`, {
      method: "POST",
      headers: { ...corsHeaders, ...jsonHeaders },
      body: JSON.stringify({ email: email }),
    }).then((response: Response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  getSequenceData(
    token: string,
    sequenceCode: string,
    format: string
  ): Promise<any> {
    return fetch(
      `${apiUrl}/${this.basePath}/${sequenceCode}/data/?format=${format}&token=${token}`,
      {
        method: "GET",
        headers: { ...corsHeaders, ...jsonHeaders },
      }
    ).then((response: Response) => {
      if (response.ok) {
        return response.text();
      }
      throw new Error(response.statusText);
    });
  }
}

const sequenceRepository = new SequenceRepository("sequences");

export default sequenceRepository;
