const reusableNotifications = {
  idleSession: (
    message: string
  ): {
    color: string;
    message: string;
    id: string;
    link: { label: string; name: string };
  } => ({
    color: "warning",
    message: `Votre session n'est plus active. Veuillez-vous reconnecter.`,
    id: message,
    link: {
      label: "Se connecter",
      name: "home",
    },
  }),
};
export default reusableNotifications;
