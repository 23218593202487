<template>
  <v-tabs
    v-model="tab"
    dark
    centered
    show-arrows
    align-with-title
    active-class="yellow text-black"
  >
    <v-tabs-slider color="yellow"></v-tabs-slider>
    <v-tab
      class="text--white"
      v-for="tabItem in tabs"
      :key="tabItem.slug"
      link
      :to="getRoute(tabItem)"
    >
      {{ tabItem.title }}
    </v-tab>
  </v-tabs>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "GuideTabs",
  data: () => ({
    tab: "/admin/eccipe",
  }),
  computed: {
    ...mapState(["trainerHelpTabs"]),
    tabs() {
      return this.trainerHelpTabs;
    },
  },
  methods: {
    getRoute(tab) {
      if (tab.slug.includes("support")) {
        return {
          name: "support",
          params: {
            slug: tab.slug.substr(tab.slug.indexOf("/") + 1, tab.slug.length),
            storeModuleName: tab.storeModuleName,
            stateEntityList: tab.stateEntityList,
            title: tab.title,
          },
        };
      }
      return {
        name: tab.slug,
      };
    },
  },
};
</script>

<style scoped>
.text-black {
  color: black !important;
}
</style>
