import Vue from "vue";
import Vuex from "vuex";
import activity from "@/store/modules/activity";
import user from "@/store/modules/user";
import item from "@/store/modules/item";
import tip from "@/store/modules/tip";
import chatroom from "@/store/modules/chatroom";
import session from "@/store/modules/session";
import mutations from "@/store/mutations";
import actions from "@/store/actions";
import getters from "@/store/getters";
import sequence from "@/store/modules/sequence";
import setting from "@/store/modules/setting";
import tipGroup from "@/store/modules/tipGroup";
import slide from "@/store/modules/slide";
import event from "@/store/modules/event";
import roomAssignment from "@/store/modules/activities/roomAssignment";
import { BaseState } from "@/store/state";

Vue.use(Vuex);

export const defaultState: BaseState = {
  isLogged: false,
  isAuthenticated: !!window.localStorage.getItem("eccipe:token") || false,
  token: window.localStorage.getItem("eccipe:token") || "",
  notifications: Array<Record<string, any>>(),
  snackText: "",
  displaySnack: false,
  snackColor: "",
  snackClosable: false,
  snackTimeOut: 3000,
  creationToolsTabs: [
    {
      id: "activity",
      title: "Activités",
      slug: "crud/activity",
      img: "creative_process_flatline.svg",
      storeModuleName: "activity",
      stateEntityList: "activities",
      icon: "mdi-draw-pen",
    },
    {
      id: "sequence",
      title: "Séquences",
      slug: "crud/sequence",
      img: "calendar_flatline.svg",
      storeModuleName: "sequence",
      stateEntityList: "sequences",
      icon: "mdi-calendar-clock",
    },
    {
      id: "tip-group",
      title: "Guidage",
      slug: "crud/tipGroup",
      img: "tip_flatline.svg",
      storeModuleName: "tipGroup",
      stateEntityList: "tipGroups",
      icon: "mdi-lightbulb-on-outline",
    },
  ],
  trainerHelpTabs: [
    {
      id: "intro",
      title: "Introduction au module",
      slug: "module",
      img: "presentation_flatline.svg",
      storeModuleName: "module",
      stateEntityList: "module",
      icon: "mdi-clipboard-file-outline",
    },
    {
      id: "coaching",
      title: "Guide d'accompagnement",
      slug: "following",
      img: "setting_flatline.svg",
      storeModuleName: "following",
      stateEntityList: "following",
      icon: "mdi-file-find-outline",
    },
    {
      id: "dashboard",
      title: "Tableau de bord",
      slug: "dashboard",
      img: "dashboard_flatline.svg",
      storeModuleName: "following",
      stateEntityList: "following",
      icon: "mdi-chart-bar",
    },
  ],
};

const store = new Vuex.Store({
  modules: {
    activity,
    setting,
    user,
    session,
    item,
    tip,
    chatroom,
    sequence,
    tipGroup,
    slide,
    event,
    roomAssignment,
  },
  strict: true,
  state: { ...defaultState },
  mutations,
  getters,
  actions,
});

export default store;
