import { ActionContext } from "vuex";
import eventRepository from "@/repository/event";
import { responseErrorHandler } from "@/utils";
import { IEvent } from "@/schemas/IEvent";

const state = {};

const event = {
  namespaced: true,
  state,
  getters: {},
  mutations: {},
  actions: {
    /**
     * Create an event
     * @param {ActionContext<any, any>} context
     * @param {Record<string, any>} payload
     */
    createEvent: (context: ActionContext<any, any>, event: IEvent): void => {
      eventRepository
        .create(context.rootState.token, event)
        .then((event) => {
          //  TODO: handle event creation
        })
        .catch((error) => responseErrorHandler(error, context));
    },
  },
};
export default event;
