import { ActionContext } from "vuex";
import sessionRepository from "@/repository/session";
import { responseErrorHandler } from "@/utils";
import clientCache from "@/utils/cacheUtils";
import { statusMessage } from "@/utils/httpUtils";
import moveRepository from "@/repository/move";
import { ISession } from "@/schemas/ISession";
import { IResult } from "@/schemas/IResult";
import { IMove } from "@/schemas/IMove";
import { IUserSessions } from "@/schemas/IUserSessions";
import { ActivityType, Scope } from "@/schemas/Enums";
import { IUser } from "@/schemas/IUser";
import sequenceRepository from "@/repository/sequence";

const actions = {
  /**
   * Retrieve all sessions and store them into store.session.sessions
   * @param context
   * @param payload { callback: CallableFunction }
   */
  fetchSessions: (
    context: ActionContext<string, any>,
    payload: { callback: CallableFunction }
  ): void => {
    sessionRepository
      .getAll(context.rootState.token)
      .then((sessions) => {
        context.commit("SET_SESSIONS", sessions);
        payload.callback(true);
      })
      .catch((error) => {
        responseErrorHandler(error, context);
      });
  },
  /**
   * Disconnect a user from a session channel
   * @param context
   * @param payload { sessionId: string; channel: string }
   */
  disconnectFromSession: (
    context: ActionContext<string, any>,
    payload: { sessionId: string; channel: string }
  ): void => {
    sessionRepository
      .disconnectFromSession(
        context.rootState.token,
        payload.sessionId,
        payload.channel
      )
      .then(() =>
        console.log(
          `Successfully disconnected from session ${payload.sessionId}, channel ${payload.channel}`
        )
      )
      .catch((error) => {
        responseErrorHandler(error, context);
      });
  },
  /**
   * Connect a user to a session channel
   * @param context
   * @param payload { sessionId: string; channel: string }
   */
  connectToSession: (
    context: ActionContext<string, any>,
    payload: { sessionId: string; channel: string }
  ): void => {
    sessionRepository
      .connectToSession(
        context.rootState.token,
        payload.sessionId,
        payload.channel
      )
      .then((connectedQueueRank) => {
        console.log(
          `Sucessfully connected to session ${payload.sessionId}-${payload.channel}. You're the ${connectedQueueRank} to be connected.`
        );
      })
      .catch((error) => {
        responseErrorHandler(error, context);
      });
  },
  /**
   * Retrieve session status
   * @param context
   * @param payload { sessionId: string; channel: string; next?: CallableFunction }
   */
  getSessionStatus: (
    context: ActionContext<string, any>,
    payload: { sessionId: string; channel: string; next?: CallableFunction }
  ): void => {
    sessionRepository
      .getSessionStatus(
        context.rootState.token,
        payload.sessionId,
        payload.channel
      )
      .then((response) => {
        if (payload.next) {
          payload.next(response);
        }
      })
      .catch((error) => {
        responseErrorHandler(error, context);
      });
  },
  /**
   * Retrieve session identified by payload.sessionId
   * @param context
   * @param payload
   */
  fetchSession: (
    context: ActionContext<string, any>,
    payload: {
      sessionId: string;
      next?: CallableFunction;
      handleFetchedSession?: CallableFunction;
    }
  ): void => {
    sessionRepository
      .getFullById(context.rootState.token, payload.sessionId)
      .then((session) => {
        console.log("LA SESSION:", session)
        handleFetchedSession(session, context);
        if (payload.next) {
          payload.next(session.status);
        }
        if (payload.handleFetchedSession) {
          payload.handleFetchedSession(session);
          console.log(payload)
        }
      })
      .catch((error) => {
        responseErrorHandler(error, context);
      });
  },
  /**
   * Create a session
   * @param context
   * @param payload { session: ISession; next?: CallableFunction }
   */
  createSession: (
    context: ActionContext<any, any>,
    payload: { session: ISession; next?: CallableFunction }
  ): void => {
    sessionRepository
      .create(context.rootState.token, payload.session)
      .then((createdSession: ISession) => {
        if (payload.next) payload.next(createdSession);
      })
      .catch((error) => responseErrorHandler(error, context));
  },
  updateSession: (
    context: ActionContext<any, any>,
    payload: { sessionId: string; session: ISession }
  ): void => {
    sessionRepository
      .update(context.rootState.token, payload.sessionId, payload.session)
      .catch((error) => responseErrorHandler(error, context));
  },
  getMove: (
    context: ActionContext<any, any>,
    payload: {id: string, sequenceId:string }
  ): void => {
    console.log(context.rootState.token)
    sequenceRepository
      .getSessionMove(context.rootState.token, payload.id, payload.sequenceId)
      .catch((error) => responseErrorHandler(error, context));
  },
  submitSessionResult: (
    context: ActionContext<any, any>,
    payload: {
      result: IResult;
      resultType: ActivityType;
      next?: CallableFunction;
    }
  ): void => {
    sessionRepository
      .submitSessionResult(
        context.rootState.token,
        payload.result,
        payload.result.type
      )
      .then((result: IResult) => {
        if (result.id) {
          context.commit(
            "SET_SNACK",
            {
              snack: true,
              snackColor: "success",
              snackText: "Classement sauvegardé",
            },
            { root: true }
          );
          if (payload.resultType === ActivityType.Ranking) {
            const ranking = JSON.parse(clientCache.get("ranked_items"));
            const rankingToSubmit: Record<string, any>[] = [];
            ranking.forEach((element: Record<string, any>, index: number) => {
              if (element && element.id) {
                rankingToSubmit.push({
                  item_id: element.id,
                  actual_rank: index + 1,
                  result_id: result.id,
                });
              }
            });

            sessionRepository
              .submitRanking(
                context.rootState.token,
                rankingToSubmit,
                result.id
              )
              .then((response) => {
                context.commit("SET_ITEMS_TO_BE_RANKED", {
                  whole: true,
                  items: [],
                });
                context.commit("SET_RANKED_ITEMS", {
                  whole: true,
                  items: Object.assign({}, Array(response.length).fill({})),
                });
                if (payload.next) payload.next();
              })
              .catch((error) => responseErrorHandler(error, context));
          } else {
            if (payload.next) {
              payload.next();
            }
          }
        }
      })
      .catch((error) => {
        if (error.message === "409") {
          if (payload.result.session_id) {
            //   We fetch the ranking results and make sure that the ranking is submitted as well
            if (payload.resultType === ActivityType.Ranking) {
              sessionRepository
                .getSessionScore(
                  context.rootState.token,
                  payload.result.session_id
                )
                .then(
                  (
                    results: {
                      user_id?: string;
                      id: string;
                      score: number;
                      scope: string;
                    }[]
                  ) => {
                    const groupRes = results.find(
                      (res) => res.scope == Scope.Group
                    );
                    if (groupRes) {
                      const ranking = JSON.parse(
                        clientCache.get("ranked_items")
                      );

                      const rankingToSubmit: Record<string, any>[] = [];
                      ranking.forEach(
                        (element: Record<string, any>, index: number) => {
                          if (element && element.id) {
                            rankingToSubmit.push({
                              item_id: element.id,
                              actual_rank: index + 1,
                              result_id: groupRes.id,
                            });
                          }
                        }
                      );

                      sessionRepository
                        .submitRanking(
                          context.rootState.token,
                          rankingToSubmit,
                          groupRes.id
                        )
                        .then((response) => {
                          context.commit("SET_ITEMS_TO_BE_RANKED", {
                            whole: true,
                            items: [],
                          });
                          context.commit("SET_RANKED_ITEMS", {
                            whole: true,
                            items: Object.assign(
                              {},
                              Array(response.length).fill({})
                            ),
                          });
                          if (payload.next) payload.next();
                        })
                        .catch((error) => responseErrorHandler(error, context));
                    }
                  }
                );
            }
          }
          if (payload.next) payload.next();
          return;
        }
        responseErrorHandler(error, context);
      });
  },


  createMove: (
    context: ActionContext<any, any>,
    payload: { move: IMove; callback?: CallableFunction }
  ): void => {
    moveRepository
      .createMove(context.rootState.token, payload.move)
      .then(() => {
        context.commit(
          "SET_SNACK",
          {
            displaySnack: true,
            snackText: "Classement sauvegardé.",
            snackColor: "success",
          },
          { root: true }
        );
        if (payload.callback) payload.callback();
      })
      .catch((error) => {
        responseErrorHandler(error, context);
        if (error.message === statusMessage["422"]) {
          context.commit(
            "SET_SNACK",
            {
              displaySnack: true,
              snackText: "Erreur enregistrement du mouvement.",
              snackColor: "warning",
            },
            { root: true }
          );
        }
      });
  },
  fetchMoves: (
    context: ActionContext<any, any>,
    payload: { sessionId: string; userId: string; callback?: CallableFunction }
  ): void => {
    moveRepository
      .getMovesBySessionIdByUserId(
        context.rootState.token,
        payload.sessionId,
        payload.userId
      )
      .then((response) => {
        if (payload.callback) payload.callback(response);

        context.commit(
          "SET_SNACK",
          {
            displaySnack: true,
            snackText: "Session chargée avec succès.",
            snackColor: "success",
          },
          { root: true }
        );
      })
      .catch((error) => {
        responseErrorHandler(error, context);
        context.commit(
          "SET_SNACK",
          {
            displaySnack: true,
            snackText: "Erreur chargement de la session.",
            snackColor: "warning",
          },
          { root: true }
        );
      });
  },
  getCachedRanking: (
    context: ActionContext<any, any>,
    sessionId: string
  ): void => {
    sessionRepository
      .getCachedRanking(context.rootState.token, sessionId)
      .then((response) => {
        context.commit("SET_CORRELATION_ID", Number(response.correlation_id));
        context.commit("SET_ITEMS_TO_BE_RANKED", {
          whole: true,
          items: response.items_to_be_ranked,
        });
        let ranked_items: any[];
        if (!response.ranked_items.length) {
          ranked_items = Object.assign({}, Array(15).fill({}));
        } else {
          if (
            response.ranked_items.length <
            context.state.session.activity.items.length
          ) {
            const toFill =
              context.state.session.activity.items.length -
              response.ranked_items.length -
              1;

            for (let i = 0; i < toFill; i++) {
              response.ranked_items.push({});
            }

            ranked_items = response.ranked_items;
          }
          ranked_items = response.ranked_items;
        }
        context.commit("SET_RANKED_ITEMS", {
          whole: true,
          items: ranked_items,
        });
        context.commit(
          "SET_SNACK",
          {
            displaySnack: true,
            snackText: "Liste récupérée avec succès.",
            snackColor: "success",
          },
          { root: true }
        );
      })
      .catch((error) => {
        responseErrorHandler(error, context);
      });
  },
  getUserWhoAgreedOnSessionEnd: (
    context: ActionContext<any, any>,
    payload: { sessionId: string; next: CallableFunction }
  ): void => {
    sessionRepository
      .getUserWhoAgreedOnSessionEnd(context.rootState.token, payload.sessionId)
      .then((response) => payload.next(response))
      .catch((error) => responseErrorHandler(error, context));
  },
  getSessionScore: (
    context: ActionContext<any, any>,
    payload: { sessionId: string; next?: CallableFunction }
  ): void => {
    sessionRepository
      .getSessionScore(context.rootState.token, payload.sessionId)
      .then((scores) => {
        context.commit("SET_SESSION_SCORES", scores);
        if (payload.next) payload.next(scores);
      })
      .catch((error) => responseErrorHandler(error, context));
  },
  getSessionParticipation: (
    context: ActionContext<any, any>,
    payload: { sessionId: string; next?: CallableFunction }
  ): void => {
    sessionRepository
      .getSessionParticipation(context.rootState.token, payload.sessionId)
      .then((participation) => {
        if (payload.next) payload.next(participation);
      })
      .catch((error) => responseErrorHandler(error, context));
  },
  getSessionWordNumber: (
    context: ActionContext<any, any>,
    payload: { sessionId: string; next?: CallableFunction }
  ): void => {
    sessionRepository
      .getSessionWordNumber(context.rootState.token, payload.sessionId)
      .then((participation) => {
        if (payload.next) payload.next(participation);
      })
      .catch((error) => responseErrorHandler(error, context));
  },
  addUserToSession: (
    context: ActionContext<any, any>,
    payload: {
      sessionId: string;
      next: CallableFunction;
      userSessions: IUserSessions[];
    }
  ): void => {
    //  TODO: add sequence_id if available
    sessionRepository
      .addUserToSession(
        context.rootState.token,
        payload.sessionId,
        payload.userSessions
      )
      .then((updatedSession: ISession) => {
        //  TODO notify
        console.log(updatedSession);
      })
      .catch((error) => responseErrorHandler(error, context));
  },
  getCachedAssignment: (
    context: ActionContext<any, any>,
    payload: { sessionId: string; next?: CallableFunction }
  ): void => {
    sessionRepository
      .getCachedAssignment(context.rootState.token, payload.sessionId)
      .then((assignment) => {
        context.commit("roomAssignment/SET_ALL_ASSIGNMENT", assignment, {
          root: true,
        });
        if (payload.next) {
          payload.next();
        }
      });
  },
  fetchSessionUsers: (
    context: ActionContext<any, any>,
    payload: {
      sessionId: string;
      next: CallableFunction;
    }
  ): void => {
    sessionRepository
      .getSessionUsers(context.rootState.token, payload.sessionId)
      .then((sessionUsers: IUser[]) => {
        context.commit("SET_SESSION_USERS", sessionUsers);
      })
      .catch((error: Error) => responseErrorHandler(error, context));
  },
  getSessionResults: (
    context: ActionContext<any, any>,
    payload: {
      sessionId: string;
      next: CallableFunction;
    }
  ): void => {
    sessionRepository
      .getSessionResults(context.rootState.token, payload.sessionId)
      .then((results: IResult[]) => {
        if (payload.next) {
          payload.next(results);
        }
      })
      .catch((error: Error) => responseErrorHandler(error, context));
  },
};

export default actions;

const handleFetchedSession = (
  session: Record<string, any>,
  context: ActionContext<any, any>
): void => {
  context.commit("SET_SESSION", session);
  const isAssignment = session.activity.type == ActivityType.Assignment;
  if (isAssignment) {
    context.commit("roomAssignment/SET_PAYOFF", session.activity.payoff, {
      root: true,
    });
    context.commit(
      "roomAssignment/SET_CONSTRAINTS",
      session.activity.constraints,
      {
        root: true,
      }
    );
    context.commit("roomAssignment/SET_ROOMS", session.activity.rooms, {
      root: true,
    });
    context.commit("roomAssignment/SET_SUBJECTS", session.activity.subjects, {
      root: true,
    });
  } else {
    const items = session.activity.items;
    if (items) {
      context.commit("SET_ITEMS_TO_BE_RANKED", {
        whole: true,
        items: JSON.parse(
          clientCache.get("items_to_be_ranked") || JSON.stringify(items)
        ),
      });
      const cacheItems = clientCache.get("ranked_items");
      context.commit("SET_RANKED_ITEMS", {
        whole: true,
        items: cacheItems
          ? JSON.parse(cacheItems).length
            ? JSON.parse(cacheItems)
            : Object.assign({}, Array(items.length).fill({}))
          : Object.assign({}, Array(items.length).fill({})),
      });
    }
  }
};
