
import { mapMutations, mapState } from "vuex";
import { UFliterals } from "@/utils/literals";
import { Component, Vue } from "vue-property-decorator";

@Component({
  name: "NotificationContainer",
  computed: {
    ...mapState([
      "notifications",
      "snackColor",
      "snackClosable",
      "snackTimeOut",
      "displaySnack",
      "snackText",
    ]),
  },
  methods: {
    ...mapMutations({
      setNotifications: "SET_NOTIFICATIONS",
      setSnack: "SET_SNACK",
      setDisplaySnack: "SET_DISPLAY_SNACK",
    }),
  },
})
export default class NotificationContainer extends Vue {
  get display(): boolean {
    return this.displaySnack;
  }

  set display(display: boolean) {
    this.setDisplaySnack(display);
  }

  removeNotification(): void {
    this.setNotifications([]);
  }

  closeSnack(): void {
    this.setSnack({});
    this.display = false;
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }
}
