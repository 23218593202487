import getters from "@/store/modules/sequence/getters";
import mutations from "@/store/modules/sequence/mutations";
import actions from "@/store/modules/sequence/actions";
import { SequenceState } from "@/store/modules/sequence/state";

const state: SequenceState = {
  sequence: {
    id: "",
    title: "",
    description: "",
    author_id: "",
    is_open: false,
  },
  sequences: [],
};

const sequence = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};

export default sequence;
