import { apiUrl } from "@/env";
import { authHeader, corsHeaders } from "@/repository/deps";

const messageRepository = {
    /**
     * Retrieves all messages
     * @param token
     * @param chatroomId
     */
    getMessages: (token: string, chatroomId: string): Promise<any> => {
        return fetch(`${apiUrl}/chat/${chatroomId}`, {
            method: "GET",
            headers: { ...authHeader(token), ...corsHeaders },
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error(response.statusText);
        });
    },
    /**
     * Deletes message identified by messageId
     * @param token
     * @param messageId
     */
    // deleteMessage: (token: string, messageId: string): Promise<any> => {
    //   return fetch(`${apiUrl}/messages/${messageId}`, {
    //     method: "DELETE",
    //     headers: { ...authHeader(token), ...corsHeaders },
    //   }).then((response) => {
    //     if (response.ok) {
    //       return response.json();
    //     }
    //     throw new Error(response.statusText);
    //   });
    // },
    // /**
    //  * Creates an message
    //  * @param token
    //  * @param message
    //  */
    // createMessage: (
    //   token: string,
    //   message: Record<string, any>
    // ): Promise<any> => {
    //   return fetch(`${apiUrl}/messages`, {
    //     method: "POST",
    //     headers: { ...authHeader(token), ...corsHeaders, ...jsonHeaders },
    //     body: JSON.stringify(message),
    //   }).then((response) => {
    //     if (response.ok) {
    //       return response.json();
    //     }
    //     throw new Error(response.statusText);
    //   });
    // },
};

export default messageRepository;
