const cacheUtils = {
  cache: (key: string, value: string | any): void => {
    window.localStorage.setItem(key, value);
  },
  clear: (key: string): void => {
    window.localStorage.removeItem(key);
  },
  get: (key: string): any => {
    return window.localStorage.getItem(key);
  },
  clearCache: (): void => {
    window.localStorage.clear();
  },
};
export default cacheUtils;
