import mutations from "@/store/modules/user/mutations.ts";
import getters from "@/store/modules/user/getters.ts";
import actions from "@/store/modules/user/actions.ts";
import { UserState } from "@/store/modules/user/state.ts";
import { Roles } from "@/schemas/Enums";

export const state: UserState = {
  users: [],
  sessions: [],
  userId: window.localStorage.getItem("userId") || "",
  username: "",
  role: Roles.User,
};

const user = {
  namespaced: true,
  state: { ...state },
  mutations,
  actions,
  getters,
};

export default user;
