import { ActionContext } from "vuex";
import tipRepository from "@/repository/tip";
import { responseErrorHandler } from "@/utils";
import { ITip } from "@/schemas/ITip";

interface tipState {
  tips: ITip[];
}

const state: tipState = {
  tips: [],
};

const tip = {
  namespaced: true,
  state,
  getters: {},
  mutations: {
    /**
     * Set state/tips with tips value
     * @param {tipState} state
     * @param {Array<Record<string, any>>} tips
     */
    SET_TIPS(state: tipState, tips: ITip[]): void {
      state.tips = tips;
    },
    /**
     * Remove tip identified by tipId from state/tips
     * @param {tipState} state
     * @param {string} tipIndex
     */
    REMOVE_TIP(state: tipState, tipIndex: number): void {
      state.tips.splice(tipIndex, 1);
    },
    /**
     * Add tip to state/tips
     * @param {tipState} state
     * @param {Record<string, any>} tip
     */
    ADD_TIP(state: tipState, tip: ITip): void {
      state.tips.push(tip);
    },
    /**
     * Set the tips of state/tips at payload/index by payload/tip
     * @param {tipState} state
     * @param {Record<string, any>} payload
     */
    UPDATE_TIP(state: tipState, payload: { index: number; tip: ITip }): void {
      Object.assign(state.tips[payload.index], payload.tip);
    },
  },
  actions: {
    /**
     * Fetch tips
     * @param {ActionContext<any, any>} context
     * @param payload
     */
    fetchTips: (
      context: ActionContext<any, any>,
      payload: Record<string, any>
    ): void => {
      tipRepository
        .getAll(context.rootState.token)
        .then((tips) => {
          context.commit("SET_TIPS", tips);
          if (payload.callback) {
            payload.callback(true);
          }
        })
        .catch((error) => responseErrorHandler(error, context));
    },
    /**
     * Delete tip identified by payload.tipId
     * @param {ActionContext<any, any>} context
     * @param {Record<string, any>} payload
     */
    deleteTip: (
      context: ActionContext<any, any>,
      payload: { id: string; next?: CallableFunction }
    ): void => {
      tipRepository
        .delete(context.rootState.token, payload.id)
        .then(() => {
          const tipIndex = context.state.tips.findIndex(
            (tip: Record<string, any>) => tip.id === payload.id
          );
          if (tipIndex) {
            context.commit("REMOVE_TIP", tipIndex);
          }
        })
        .catch((error) => responseErrorHandler(error, context));
    },
    /**
     * Create a tip
     * @param {ActionContext<any, any>} context
     * @param {Record<string, any>} payload
     */
    createTip: (
      context: ActionContext<any, any>,
      payload: Record<string, any>
    ): void => {
      tipRepository
        .create(context.rootState.token, payload.tip)
        .then((tip) => {
          context.commit("ADD_TIP", tip);
        })
        .catch((error) => responseErrorHandler(error, context));
    },
    createTips: (context: ActionContext<any, any>, tips: ITip[]): void => {
      tipRepository
        .createMultiple(context.rootState.token, tips)
        // .then((tips: ITip[]) => {
        //   console.log(tips);
        // })
        .catch((error: any) => responseErrorHandler(error, context));
    },
    updateTip: (
      context: ActionContext<any, any>,
      payload: { id: string; tip: ITip }
    ): void => {
      tipRepository
        .update(context.rootState.token, payload.id, { ...payload.tip })
        // .then((updatedTip) => {
        //   console.log(updatedTip);
        // })
        .catch((error) => responseErrorHandler(error, context));
    },
  },
};
export default tip;
