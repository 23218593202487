import { BaseState } from "@/store/state";
import Vue from "vue";
import { defaultState } from "@/store/index";

const mutations = {
  CLEAR_STATE(state: BaseState): void {
    Object.assign(state, { ...defaultState });
    Vue.set(state, "token", "");
    Vue.set(state, "isAuthenticated", false);
    Vue.set(state, "isLogged", false);
    Vue.set(state, "user/userId", "false");
    Vue.set(state, "user/username", "");
    // state = Object.assign({}, { ...defaultState });
  },
  SET_TOKEN: (state: BaseState, token: string): void => {
    state.token = token;
    window.localStorage.setItem("eccipe:token", token);
  },
  SET_RENEWAL_TOKEN: (state: BaseState, renewal_token: string): void => {
    window.localStorage.setItem("eccipe:renewal_token", renewal_token);
  },
  SET_IS_AUTHENTICATED: (state: BaseState, isAuthenticated: boolean): void => {
    state.isAuthenticated = isAuthenticated;
  },
  SET_NOTIFICATIONS: (
    state: BaseState,
    notifications: Record<string, any>[]
  ): void => {
    state.notifications = [...notifications];
  },
  SET_SNACK: (
    state: BaseState,
    snack: {
      snackText?: string;
      snackColor?: string;
      snackClosable?: boolean;
      snackTimeOut?: number;
    }
  ): void => {
    state.displaySnack = true;
    state.snackText = snack.snackText || "";
    state.snackColor = snack.snackColor || "green darken-4";
    state.snackClosable = snack.snackClosable || false;
    state.snackTimeOut = snack.snackTimeOut || 2000;
  },
  SET_DISPLAY_SNACK: (state: BaseState, display: boolean): void => {
    state.displaySnack = display;
  },
};

export default mutations;
