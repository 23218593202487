import { BaseRepository } from "@/repository/base";
import { IActivity } from "@/schemas/IActivity";
import {apiUrl} from "@/env";
import {authHeader} from "@/repository/deps";

class ActivityRepository extends BaseRepository<IActivity> {
    uploadBackground(token: string, file: File) {
        const formData = new FormData();
        formData.set("file", file);
        return fetch(`${apiUrl}/${this.basePath}/background`, {
            method: "POST",
            headers: { ...authHeader(token) },
            body: formData,
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            throw new Error(response.statusText);
        });
    }

    deleteBackground(token: string, fileName: string, slideId?: string) {
        const resource: string = slideId
            ? `${apiUrl}/${this.basePath}/${slideId}/files/${fileName}`
            : `${apiUrl}/${this.basePath}/files/${fileName}`;
        return fetch(resource, {
            method: "DELETE",
            headers: { ...authHeader(token) },
        }).then((response) => {
            if (response.ok) {
                return true;
            }
            throw new Error(response.statusText);
        });
    }
}

const activityRepository = new ActivityRepository("activities");
export default activityRepository;
