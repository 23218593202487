import actions from "@/store/modules/slide/actions.ts";
import { SlideState } from "@/store/modules/slide/state";

const state: SlideState = {
  slide: { order_number: 0, elements: [] },
  slides: [],
};

const slide = {
  namespaced: true,
  state,
  actions,
};

export default slide;
