import { ActionContext } from "vuex";
import tipGroupRepository from "@/repository/tipGroup";
import { responseErrorHandler } from "@/utils";
import { ITip } from "@/schemas/ITip";
import { ITipGroup } from "@/schemas/ITipGroup";

interface tipGroupState {
  tipGroups: Record<string, any>[];
}

const state: tipGroupState = {
  tipGroups: [],
};

const tipGroup = {
  namespaced: true,
  state,
  getters: {},
  mutations: {
    /**
     * Set state.tipGroups with tipGroups value
     * @param {tipGroupState} state
     * @param {Array<Record<string, any>>} tipGroups
     */
    SET_TIP_GROUPS(
      state: tipGroupState,
      tipGroups: Array<Record<string, any>>
    ): void {
      state.tipGroups = tipGroups;
    },
    /**
     * Remove tipGroup identified by tipGroupId from state.tipGroups
     * @param {tipGroupState} state
     * @param {string} tipGroupIndex
     */
    REMOVE_TIP_GROUP(state: tipGroupState, tipGroupIndex: number): void {
      state.tipGroups.splice(tipGroupIndex, 1);
    },
    /**
     * Add tipGroup to state.tipGroups
     * @param {tipGroupState} state
     * @param {Record<string, any>} tipGroup
     */
    ADD_TIP_GROUP(state: tipGroupState, tipGroup: Record<string, any>): void {
      state.tipGroups.push(tipGroup);
    },
    /**
     * Set the tipGroups of state.tipGroups at payload/index by payload/tipGroup
     * @param {tipGroupState} state
     * @param {Record<string, any>} payload
     */
    UPDATE_TIP_GROUP(state: tipGroupState, payload: Record<string, any>): void {
      Object.assign(state.tipGroups[payload.index], payload.tipGroup);
    },
  },
  actions: {
    /**
     * Fetch tipGroups
     * @param context
     * @param payload
     */
    fetchTipGroups: (
      context: ActionContext<any, any>,
      payload: { callback: CallableFunction; next?: CallableFunction }
    ): void => {
      tipGroupRepository
        .getAll(context.rootState.token)
        .then((tipGroups) => {
          context.commit("SET_TIP_GROUPS", tipGroups);
          if (payload.callback) {
            payload.callback(true);
          }
          if (payload.next) payload.next(tipGroups);
        })
        .catch((error) => responseErrorHandler(error, context));
    },
    /**
     * Delete a tipGroup by its identifier
     * @param context
     * @param payload
     */
    deleteTipGroup: (
      context: ActionContext<any, any>,
      payload: { id: string; next?: CallableFunction }
    ): void => {
      tipGroupRepository
        .delete(context.rootState.token, payload.id)
        .then(() => {
          const tipGroupIndex = context.state.tipGroups.findIndex(
            (tipGroup: Record<string, any>) => tipGroup.id === payload.id
          );
          if (tipGroupIndex) {
            context.commit("REMOVE_TIP_GROUP", tipGroupIndex);
          }
        })
        .catch((error) => responseErrorHandler(error, context));
    },
    /**
     * Create a tipGroup
     * @param context
     * @param payload { tipGroup: ITipGroup }
     */
    createTipGroup: (
      context: ActionContext<any, any>,
      payload: { tipGroup: ITipGroup }
    ): void => {
      tipGroupRepository
        .create(context.rootState.token, payload.tipGroup)
        .then((createdTipGroup: ITipGroup) => {
          context.commit("ADD_TIP_GROUP", createdTipGroup);

          let tips = payload.tipGroup.tips;
          if (tips?.length) {
            tips = tips?.filter((tip) => Object.entries(tip).length !== 0);
            tips?.map((tip: ITip) => (tip.tip_group_id = createdTipGroup.id));
            context.dispatch("tip/createTips", tips, { root: true });
          }
        })
        .catch((error) => responseErrorHandler(error, context));
    },
    fetchFullById: (
      context: ActionContext<any, any>,
      payload: { id: string; next?: CallableFunction }
    ): void => {
      tipGroupRepository
        .getFullById(context.rootState.token, payload.id)
        .then((response) => {
          if (payload.next) {
            payload.next(response);
          }
        })
        .catch((error) => console.log(error));
    },
    updateTipGroup: (
      context: ActionContext<any, any>,
      payload: { id: string; tipGroup: ITipGroup }
    ): void => {
      tipGroupRepository
        .update(context.rootState.token, payload.id, payload.tipGroup)
        .then((updatedTipGroup) => {
          const updatedIndex = context.state.tipGroups.findIndex(
            (tipGroup: ITipGroup) => tipGroup.id === updatedTipGroup.id
          );
          if (updatedIndex) {
            context.commit("UPDATE_TIP_GROUP", {
              index: updatedIndex,
              tipGroup: updatedTipGroup,
            });
          }
          if (payload.tipGroup.tips?.length) {
            const tips: ITip[] = payload.tipGroup.tips.filter(
              (tip) => Object.entries(tip).length !== 0
            );
            const tipToUpdate = [...tips].filter((tip) => !!tip.id);
            if (tipToUpdate.length) {
              tipToUpdate.forEach((tip) =>
                context.dispatch(
                  "tip/updateTip",
                  { id: tip.id, tip: tip },
                  { root: true }
                )
              );
            }
            const tipToCreate = tips.filter((tip) => !tip.id);
            if (tipToCreate.length) {
              tips?.map((tip) => (tip.tip_group_id = payload.tipGroup.id));
              context.dispatch("tip/createTips", tipToCreate, {
                root: true,
              });
            }
          }
        })
        .catch((error) => responseErrorHandler(error, context));
    },
  },
};
export default tipGroup;
