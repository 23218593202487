import { UserState } from "@/store/modules/user/state";
import { Roles } from "@/schemas/Enums";
import {
  canCreate,
  canDelete,
  canDuplicate,
  canEnterAdmin,
} from "@/config/accessGranting";

const getters = {
  getUserId: (state: UserState): string =>
    window.sessionStorage.getItem("userId") || state.userId,
  isAdmin: (state: UserState): CallableFunction => {
    return function () {
      return state.role === Roles.User
        ? null
        : canEnterAdmin.includes(state.role);
    };
  },
  canEnterAdmin: (state: UserState): boolean => {
    return canEnterAdmin.includes(state.role);
  },
  isInstructor: (state: UserState): boolean => {
    return state.role === Roles.Instructor;
  },
  isResearcher: (state: UserState): boolean => {
    return (state.role === Roles.Researcher || state.role === Roles.Instructor);
  },
  isAdministrator: (state: UserState): boolean => {
    return state.role === Roles.Admin;
  },
  canDuplicate: (state: UserState): boolean => {
    return canDuplicate.includes(state.role);
  },
  canCreate: (state: UserState): boolean => {
    return canCreate.includes(state.role);
  },
  canDelete: (state: UserState): boolean => {
    return canDelete.includes(state.role);
  },
};

export default getters;
