<script>
import Header from "./AppHeader.vue";
import Footer from "./AppFooter.vue";
import NotificationContainer from "@/components/layout/NotificationContainer";

export default {
  components: { NotificationContainer, Footer, Header },
};
</script>
<template>
  <v-app>
    <Header />
    <v-main class="mt-14" id="main-container">
      <v-container class="main-container">
        <NotificationContainer />
        <router-view :key="$route.path" />
      </v-container>
    </v-main>
    <Footer />
  </v-app>
</template>
<style>
@media screen and (max-width: 700px) {
  .main-container {
    max-width: 100% !important;
    width: 100% !important;
  }
}

.main-container {
  max-width: 80%;
  width: 80%;
}
.main-container > .container {
  max-width: 100%;
  width: 100%;
}
</style>
