
import { mapGetters, mapState } from "vuex";
import { UFliterals } from "@/utils/literals";
import { Component, Vue } from "vue-property-decorator";

import AdminTabs from "@/components/layout/AdminTabs.vue";
import GuideTabs from "@/components/layout/GuideTabs.vue";
import { UFPageTitle } from "@/utils/literals/views";
import Logo from "@/components/identity/Logo.vue";
import { assetsUrl, toolbarColor as toolbarEnvColor } from "@/env";

import colors from 'vuetify/lib/util/colors';

@Component({
  components: { Logo, AdminTabs, GuideTabs },
  computed: {
    ...mapState("user", ["username"]),
    ...mapState(["isAuthenticated", "creationToolsTabs", "trainerHelpTabs"]),
    ...mapGetters("user", ["canEnterAdmin"]),
  },
})
export default class AppHeader extends Vue {
  username!: string;

  private drawer = false;
  private group = null;

  toolbarColor = colors.grey.darken4

  canEnterAdmin: boolean | undefined;
  isAuthenticated: boolean | undefined;
  creationToolsTabs:
    | {
        title: string;
        slug: string;
        img: string;
        storeModuleName?: string;
        stateEntityList?: string;
        icon?: string;
      }[]
    | undefined;
  trainerHelpTabs:
    | {
        title: string;
        slug: string;
        img: string;
        storeModuleName?: string;
        stateEntityList?: string;
        icon?: string;
      }[]
    | undefined;

  created(): void {
    if (this.username === "" && this.$store.state.isAuthenticated) {
      this.$store.dispatch("user/fetchCurrentUser");
    }
  }

  get pageTitle(): string {
    if (this.$route.name) {
      return UFPageTitle[this.$route.name];
    }
    return "";
  }

  getUF(key: string): string {
    return UFliterals[key] || "";
  }

  logOut(): void {
    window.localStorage.clear();
    this.$store.commit("CLEAR_STATE");
    this.$store.commit("user/CLEAR_STATE");
    this.$router.push({ name: "home" }).catch((navigationError) => {
      // Ignore the vuex error regarding navigating to the page user is already on.
      if (
        navigationError.name !== "NavigationDuplicated" &&
        !navigationError.message.includes(
          "Avoided redundant navigation to current location"
        )
      ) {
        console.error(navigationError);
      }
    });
  }

  getImageSrc(slug: string): string {
    return `${assetsUrl}/${slug}`;
  }

  mounted() {
    this.toolbarColor = toolbarEnvColor
  }
}
