import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route } from "vue-router";
import store from "@/store";
import { isCompatibleBrowser } from "@/utils/sniffing";
import { routes } from "@/router/routes";

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: "history",
  linkExactActiveClass: "router-link-exact-active",
});

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
  // check if Browser is supported
  if (to.matched.some((record) => record.meta.browserCompatibility)) {
    if (!isCompatibleBrowser()) {
      store.commit("SET_NOTIFICATIONS", []);
      next({ name: "notSupported" });
      return;
    }
  }
  // FIXME: auth pop-up display
  if (
    store.state.notifications.find(
      (notif) =>
        notif.message ===
        `Votre session n'est plus active. Veuillez-vous reconnecter.`
    )
  ) {
    store.commit("SET_NOTIFICATIONS", []);
  }
  // Check if the page requires the user to be authenticated
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.state.token || !store.state.isAuthenticated) {
      next({ name: "login", query: { redirect: to.fullPath } });
      return;
      // FIXME : check if user token is not expired too
    }
  }
  // Check if the page requires the user to be an admin
  if (to.matched.some((record) => record.meta.requiresAdmin)) {
    await store.dispatch("user/fetchCurrentUser");
    if (store.getters["user/isAdmin"]() === null) {
      const watcher = store.watch(store.getters["user/isAdmin"], (isAdmin) => {
        watcher();
        isAdmin ? next() : next({ name: "notFound" });
      });
      return;
    } else if (store.getters["user/isAdmin"]()) {
      next();
      return;
    } else {
      next({ name: "notFound" });
      return;
    }
  }
  next();
});

export default router;
