import { ISetting } from "@/schemas/ISetting";
import Vue from "vue";
import { SettingState } from "@/store/modules/setting/state";

const mutations = {
  /**
   * Set state/setting with setting value
   * @param state
   * @param payload
   * @constructor
   */
  SET_SETTING: (state: SettingState, payload: ISetting): void => {
    state.setting = payload;
  },
  /**
   * Set state/settings with settings value
   * @param state
   * @param sessions
   * @constructor
   */
  SET_SETTINGS: (state: SettingState, sessions: ISetting[]): void => {
    state.settings = sessions;
  },
  /**
   * Add a new setting to state/settings list of settings
   * @param state
   * @param setting
   * @constructor
   */
  ADD_SETTING: (state: SettingState, setting: ISetting): void => {
    const settings = [...state.settings];
    settings.push(setting);
    Vue.set(state, "settings", settings);
  },
  /**
   * Remove a setting from state/settings list of settings
   * @param state
   * @param settingId
   * @constructor
   */
  REMOVE_SETTING: (state: SettingState, settingId: string): void => {
    Vue.set(
      state,
      "settings",
      state.settings.filter((setting: ISetting) => setting.id !== settingId)
    );
  },
};

export default mutations;
