import { apiUrl } from "@/env";
import { authHeader, corsHeaders, jsonHeaders } from "@/repository/deps";
import { IMove } from "@/schemas/IMove";

const moveRepository = {
  /**
   * Create a move
   * @param token
   * @param move
   */
  createMove: (token: string, move: IMove): Promise<any> => {
    return fetch(`${apiUrl}/moves/`, {
      method: "POST",
      headers: { ...authHeader(token), ...corsHeaders, ...jsonHeaders },
      body: JSON.stringify(move),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  },
  /**
   * Retrieves moves done by user identified by userId for the session identified by sessionId
   * @param token
   * @param sessionId
   * @param userId
   */
  getMovesBySessionIdByUserId: (
    token: string,
    sessionId: string,
    userId: string
  ): Promise<any> => {
    return fetch(`${apiUrl}/moves/${sessionId}/${userId}`, {
      method: "GET",
      headers: { ...authHeader(token), ...corsHeaders },
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  },
};
export default moveRepository;
