import { ActionContext } from "vuex";
import activityRepository from "@/repository/activity";
import { IActivity } from "@/schemas/IActivity";
import { responseErrorHandler } from "@/utils";
import Vue from "vue";
import { IItem } from "@/schemas/IItem";
import { ISlide } from "@/schemas/ISlide";
import { ActivityType } from "@/schemas/Enums";

interface ActivityState {
  activities: IActivity[];
}

const state: ActivityState = {
  activities: [],
};

const handleActivityItems = (
  items: IItem[],
  activityId: string,
  context: ActionContext<any, any>,
  update = false
) => {
  // TODO: refactor as interface method
  items = items?.filter((item) => Object.entries(item).length !== 0);
  if (items?.length) {
    let itemsToCreate = items;
    if (update) {
      itemsToCreate = items.filter((item) => !item.id);
      const itemsToUpdate = [...items].filter((item) => !!item.id);
      if (itemsToUpdate.length) {
        itemsToUpdate.forEach((item) =>
          context.dispatch(
            "item/updateItem",
            { id: item.id, item: item },
            { root: true }
          )
        );
      }
    }
    if (itemsToCreate.length) {
      items?.map((item) => (item.activity_id = activityId));
      context.dispatch("item/createItems", itemsToCreate, { root: true });
    }
  }
};

const handleActivitySlides = (
  slides: ISlide[],
  activityId: string,
  context: ActionContext<any, any>,
  update = false
) => {
  // TODO: refactor as interface method
  slides = slides?.filter((slide) => Object.entries(slide).length !== 0);
  if (slides?.length) {
    let slideToCreate = slides;
    if (update) {
      slideToCreate = slides.filter((slide) => !slide.id);
      const slidesToUpdate = [...slides].filter((slide) => !!slide.id);
      if (slidesToUpdate.length) {
        slidesToUpdate.forEach((slide) =>
          context.dispatch(
            "slide/updateSlide",
            { id: slide.id, slide: slide },
            { root: true }
          )
        );
      }
    }
    if (slideToCreate.length) {
      slides?.map((slide) => (slide.activity_id = activityId));
      context.dispatch("slide/createSlides", slides, { root: true });
    }
  }
};

function handleActivityNestedAttributes(
  activity: IActivity,
  processedActivity: IActivity,
  update = false,
  context: ActionContext<any, any>
) {
  if (processedActivity.id) {
    if (activity.items) {
      handleActivityItems(
        [...activity.items],
        processedActivity.id,
        context,
        update
      );
    }
    if (activity.slides) {
      handleActivitySlides(
        [...activity.slides],
        processedActivity.id,
        context,
        update
      );
    }
  }
}

const activity = {
  namespaced: true,
  state: state,
  mutations: {
    /**
     * Set state/activities with activities value
     * @param state
     * @param activities
     * @constructor
     */
    SET_ACTIVITIES(state: ActivityState, activities: IActivity[]): void {
      state.activities = activities;
    },
    /**
     * Set the activity of state/activities at payload/index by payload/activity
     * @param state
     * @param payload
     * @constructor
     */
    UPDATE_ACTIVITY(
      state: ActivityState,
      payload: { index: number; activity: IActivity }
    ): void {
      Object.assign(state.activities[payload.index], payload.activity);
    },
    /**
     * Add a new activity to state/activities list of activities
     * @param state
     * @param activity
     * @constructor
     */
    ADD_ACTIVITY(state: ActivityState, activity: IActivity): void {
      const activities = [...state.activities];
      activities.push(activity);
      Vue.set(state, "activities", activities);
    },
    /**
     * Remove an activity from state/activities list of activities
     * @param state
     * @param activityId
     * @constructor
     */
    REMOVE_ACTIVITY(state: ActivityState, activityId: string): void {
      Vue.set(
        state,
        "activities",
        state.activities.filter((activity) => activity.id !== activityId)
      );
    },
  },
  actions: {
    uploadBackground: (
      context: ActionContext<any, any>,
      payload: { file: File }
    ): void => {
      activityRepository
        .uploadBackground(context.rootState.token, payload.file)
        .then((response) => {
          console.log(response);
        })
        .catch((error) => responseErrorHandler(error, context));
    },
    /**
     * Fetch activities
     * @param context
     * @param payload { callback: CallableFunction; next: CallableFunction }
     */
    fetchActivities: (
      context: ActionContext<any, any>,
      payload: { callback?: CallableFunction; next?: CallableFunction }
    ): void => {
      activityRepository
        .getAll(context.rootState.token)
        .then((activities) => {
          context.commit("SET_ACTIVITIES", activities);
          if (payload.callback) {
            payload.callback(true);
          }
          if (payload.next) {
            payload.next(activities);
          }
        })
        .catch((error) => responseErrorHandler(error, context));
    },
    /**
     * Delete activity identified by activityId
     * @param context
     * @param payload { activityId: string }
     */
    deleteActivity: (
      context: ActionContext<any, any>,
      payload: { id: string }
    ): void => {
      activityRepository
        .delete(context.rootState.token, payload.id)
        .then((deleted) => {
          if (deleted) {
            context.commit("REMOVE_ACTIVITY", payload.id);
          }
        })
        .catch((error) => responseErrorHandler(error, context));
    },
    /**
     * Create an activity with values of payload/activity
     * @param context
     * @param payload { activity: IActivity }
     */
    createActivity: (
      context: ActionContext<any, any>,
      payload: { activity: IActivity; callback?: CallableFunction }
    ): void => {
      if (payload.activity.type === ActivityType.Ranking) {
        activityRepository
          .create(
            context.rootState.token,
            payload.activity,
            ActivityType.Ranking
          )
          .then((createdActivity: IActivity) => {
            context.commit("ADD_ACTIVITY", createdActivity);
            if (payload.callback) {
              payload.callback(createdActivity);
            }
            handleActivityNestedAttributes(
              payload.activity,
              createdActivity,
              false,
              context
            );
          })
          .catch((error) => responseErrorHandler(error, context));
      } else {
        activityRepository
          .create(context.rootState.token, payload.activity)
          .then((createdActivity) => {
            context.commit("ADD_ACTIVITY", createdActivity);
          })
          .catch((error) => responseErrorHandler(error, context));
      }
    },
    /**
     * Update activity identified by payload.activityId with payload/activity
     * @param context
     * @param payload { activityId:string, activity:IActivity }
     */
    updateActivity: (
      context: ActionContext<any, any>,
      payload: { id: string; activity: IActivity }
    ): void => {
      activityRepository
        .update(
          context.rootState.token,
          payload.id,
          payload.activity,
          payload.activity.type
        )
        .then((updatedActivity) => {
          const updatedActivityIndex = context.state.activities.findIndex(
            (activity: IActivity) => activity.id === updatedActivity.id
          );
          if (updatedActivityIndex !== -1) {
            context.commit("UPDATE_ACTIVITY", {
              index: updatedActivityIndex,
              activity: updatedActivity,
            });
          }
          handleActivityNestedAttributes(
            payload.activity,
            updatedActivity,
            true,
            context
          );
        })
        .catch((error) => responseErrorHandler(error, context));
    },
    /**
     * Retrieves full activity by its identifier
     * @param context
     * @param payload { itemId: string; next: CallableFunction }
     */
    fetchFullById: (
      context: ActionContext<any, any>,
      payload: { id: string; next?: CallableFunction; type?: ActivityType }
    ): void => {
      activityRepository
        .getFullById(context.rootState.token, payload.id, payload.type)
        .then((activity) => {
          if (payload.next) {
            payload.next(activity);
          }
        })
        .catch((error) => responseErrorHandler(error, context));
    },

    fetchById: (
      context: ActionContext<any, any>,
      payload: { id: string; next?: CallableFunction; type?: ActivityType }
    ): void => {
      activityRepository
        .getById(context.rootState.token, payload.id, payload.type)
        .then((activity) => {
          if (payload.next) {
            payload.next(activity);
          }
        })
        .catch((error) => responseErrorHandler(error, context));
    },
  },
  modules: {},
};

export default activity;
