export const UFPageTitle: Record<string, string> = {
  login: "Connexion",
  registration: "Inscription",
  dashboard: "Tableau de bord",
  profile: "Profil",
  session: "Session",
  groupSession: "Session de groupe",
  home: "Accueil",
  notFound: "404 - Page non trouvée",
  admin: "Administration",
  crud: "Edition de contenu",
  eccipe: "Administration - ECCIPE",
  sequences: "Administration - Séquences",
  singleSession: "Session individuelle",
  transitionSession: "Session",
  sequence: "Séquence",
  roomAssignment: "Attribution de pièces",
  about: "A propos",
};
