import { BaseRepository } from "@/repository/base";
import { ISlide } from "@/schemas/ISlide";
import { apiUrl } from "@/env";
import { authHeader, corsHeaders, jsonHeaders } from "@/repository/deps";
import { ISlideElement } from "@/schemas/ISlideElement";

class SlideRepository extends BaseRepository<ISlide> {
  uploadSlideFile(token: string, file: File) {
    const formData = new FormData();
    formData.set("file", file);
    return fetch(`${apiUrl}/${this.basePath}/files`, {
      method: "POST",
      headers: { ...authHeader(token) },
      body: formData,
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  duplicateImage(token: string, slideId: string, fileName: string) {
    const resource = `${apiUrl}/${this.basePath}/files/duplicate`;
    return fetch(resource, {
      method: "POST",
      headers: { ...authHeader(token), ...jsonHeaders },
      body: JSON.stringify({ slide_id: slideId, file_name: fileName }),
    }).then((response) => {
      if (response.ok) {
        return true;
      }
      throw new Error(response.statusText);
    });
  }

  deleteSlideFile(token: string, fileName: string, slideId?: string) {
    const resource: string = slideId
      ? `${apiUrl}/${this.basePath}/${slideId}/files/${fileName}`
      : `${apiUrl}/${this.basePath}/files/${fileName}`;
    return fetch(resource, {
      method: "DELETE",
      headers: { ...authHeader(token) },
    }).then((response) => {
      if (response.ok) {
        return true;
      }
      throw new Error(response.statusText);
    });
  }

  createMultipleElements(token: string, elements: ISlideElement[]) {
    return fetch(`${apiUrl}/${this.basePath}/elements`, {
      method: "POST",
      headers: { ...authHeader(token), ...corsHeaders, ...jsonHeaders },
      body: JSON.stringify(elements),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }

  deleteSlideElement(token: string, slideElementId: string) {
    return fetch(`${apiUrl}/${this.basePath}/elements/${slideElementId}`, {
      method: "DELETE",
      headers: { ...authHeader(token) },
    }).then((response) => {
      if (response.ok) {
        return true;
      }
      throw new Error(response.statusText);
    });
  }

  updateElement(
    token: string,
    slideElementId: string,
    elementToUpdate: ISlideElement
  ) {
    return fetch(`${apiUrl}/${this.basePath}/elements/${slideElementId}`, {
      method: "PATCH",
      headers: { ...authHeader(token), ...corsHeaders, ...jsonHeaders },
      body: JSON.stringify(elementToUpdate),
    }).then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error(response.statusText);
    });
  }
}

const slideRepository = new SlideRepository("slides");

export default slideRepository;
