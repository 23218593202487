import colors from "vuetify/lib/util/colors";

export enum EnvEnum {
  Preprod = "preprod",
  Dev = "development",
  Production = "production",
  Beta = "beta",
  Test = "test",
}

export const env = process.env.VUE_APP_ENV;

let httpProtocol = "http";
let webSocketBaseUrl = ``;

if (
  env === EnvEnum.Production ||
  env === EnvEnum.Beta ||
  env === EnvEnum.Preprod
) {
  httpProtocol = "https";
  webSocketBaseUrl = `wss://${process.env.VUE_APP_API_DOMAIN}/ws`;
}

if (env === EnvEnum.Dev || env === EnvEnum.Test) {
  webSocketBaseUrl = `ws://${process.env.VUE_APP_API_DOMAIN}/ws`;
}

import color from "vuetify/lib/util/colors"

export let toolbarColor: string = colors.grey.darken4;
const devs = ["sgt", "timothe", "theo", "axel"]

for (const dev of devs) {
  if (process.env.VUE_APP_DOMAIN.includes(dev + '.')) {
    toolbarColor = colors.red.darken3
  }
}

if (process.env.VUE_APP_DOMAIN.includes("testing.")) {
  toolbarColor = colors.blue.darken3;
} else if (process.env.VUE_APP_DOMAIN.includes("staging.")) {
  toolbarColor = colors.green.darken3
}

export const assetsUrl = `${httpProtocol}://${process.env.VUE_APP_DOMAIN}/${process.env.VUE_APP_ASSETS_DIR}/`;
export const apiUrl = `${httpProtocol}://${process.env.VUE_APP_API_DOMAIN}`;
export const webSocketUrl = webSocketBaseUrl;
export const appName = process.env.VUE_APP_NAME;
export const apiBaseDomain = process.env.VUE_APP_API_DOMAIN;
export const appBaseDomain = process.env.VUE_APP_DOMAIN;
export const sentryDSN = process.env.VUE_APP_SENTRY_DSN;
