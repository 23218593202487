import { ActionContext } from "vuex";
import slideRepository from "@/repository/slide";
import { responseErrorHandler } from "@/utils";
import { ISlide } from "@/schemas/ISlide";
import { ISlideElement } from "@/schemas/ISlideElement";
import { statusMessage } from "@/utils/httpUtils";

const handleSlideElements = (
  slideElements: ISlideElement[],
  slideId: string,
  context: ActionContext<any, any>,
  update = false
) => {
  // TODO: refactor as interface method
  slideElements = slideElements?.filter(
    (el) => Object.entries(el).length !== 0
  );
  if (slideElements?.length) {
    let elToCreate = slideElements;
    if (update) {
      elToCreate = slideElements.filter((el) => !el.id);
      const elementsToUpdate = [...slideElements].filter((el) => !!el.id);
      if (elementsToUpdate.length) {
        elementsToUpdate.forEach((el) => {
          context.dispatch("updateSlideElement", {
            element: el,
            id: el.id,
          });
        });
      }
    }
    if (elToCreate.length) {
      elToCreate?.map((el) => (el.slide_id = slideId));
      context.dispatch("slide/createSlideElements", elToCreate, {
        root: true,
      });
    }
  }
};

const actions = {
  /**
   * Create a slide
   * @param {ActionContext<any, any>} context
   * @param {Record<string, any>} payload
   */
  createSlide: (
    context: ActionContext<any, any>,
    payload: { slide: ISlide }
  ): void => {
    const slide_to_create = { ...payload.slide, elements: [] };
    slideRepository
      .create(context.rootState.token, slide_to_create)
      .then((createdSlide: ISlide) => {
        if (createdSlide.id) {
          handleSlideElements(
            payload.slide.elements,
            createdSlide.id,
            context,
            false
          );
        }
      })
      .catch((error) => responseErrorHandler(error, context));
  },
  /**
   * Update a slide
   * @param context
   * @param payload
   */
  updateSlide: (
    context: ActionContext<any, any>,
    payload: { slide: ISlide; id: string }
  ): void => {
    slideRepository
      .update(context.rootState.token, payload.id, payload.slide)
      .then((updatedSlide: ISlide) => {
        if (updatedSlide.id) {
          handleSlideElements(
            payload.slide.elements,
            updatedSlide.id,
            context,
            true
          );
        }
      })
      .catch((error) => responseErrorHandler(error, context));
  },
  /**
   * Creates multiple slides
   * @param context
   * @param slides
   */
  createSlides: (context: ActionContext<any, any>, slides: ISlide[]): void => {
    slides.forEach((slide) => {
      context.dispatch("createSlide", {
        slide: { ...slide },
      });
    });
  },
  createSlideElements: (
    context: ActionContext<any, any>,
    elements: ISlideElement[]
  ): void => {
    slideRepository
      .createMultipleElements(context.rootState.token, elements)
      .then((elements) => {
        console.log(elements);
      })
      .catch((error) => responseErrorHandler(error, context));
  },
  updateSlideElement: (
    context: ActionContext<any, any>,
    payload: { element: ISlideElement; id: string }
  ): void => {
    slideRepository
      .updateElement(context.rootState.token, payload.id, payload.element)
      .then(() => {
        //  TODO handle response
      })
      .catch((error) => responseErrorHandler(error, context));
  },
  /**
   * Upload slide file
   * @param context
   * @param payload
   */
  uploadFile: (
    context: ActionContext<any, any>,
    payload: { file: File }
  ): void => {
    slideRepository
      .uploadSlideFile(context.rootState.token, payload.file)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => responseErrorHandler(error, context));
  },

  /**
   * Delete a previously uploaded file
   * @param context
   * @param payload
   */
  deleteSlideFile: (
    context: ActionContext<any, any>,
    payload: { fileName: string; slideId?: string }
  ): void => {
    slideRepository
      .deleteSlideFile(
        context.rootState.token,
        payload.fileName,
        payload.slideId
      )
      .then((response) => {
        console.log(response);
      })
      .catch((error) => responseErrorHandler(error, context));
  },

  deleteSlide: (
      context: ActionContext<any, any>,
      payload: { id: string; next?: CallableFunction }
  ): void => {
    slideRepository
        .delete(context.rootState.token, payload.id)
        .then((isDeleted) => {
          if (payload.next) payload.next(isDeleted);
        })
        .catch((error) => {
          if (error.message === statusMessage["404"]) {
            if (payload.next) payload.next(true);
          } else {
            responseErrorHandler(error, context);
          }
        });
  },

  /**
   * Delete a slide element
   * @param context
   * @param payload
   */
  deleteSlideElement: (
    context: ActionContext<any, any>,
    payload: { id: string; next?: CallableFunction }
  ): void => {
    slideRepository
      .deleteSlideElement(context.rootState.token, payload.id)
      .then((isDeleted) => {
        if (payload.next) payload.next(isDeleted);
      })
      .catch((error) => {
        if (error.message === statusMessage["404"]) {
          if (payload.next) payload.next(true);
        } else {
          responseErrorHandler(error, context);
        }
      });
  },
};
export default actions;
