import { IUserProfile } from "@/schemas/IUserProfile";
import { UserState } from "@/store/modules/user/state.ts";
import { state as userState } from "@/store/modules/user/index.ts";
import { Roles } from "@/schemas/Enums";

const mutations = {
  CLEAR_STATE(state: UserState): void {
    Object.assign(state, userState);
  },
  SET_USERS(state: UserState, payload: IUserProfile[]): void {
    state.users = payload;
  },
  SET_USER_SESSIONS(state: UserState, payload: []): void {
    state.sessions = payload;
  },
  SET_USER_ID: (state: UserState, id: string): void => {
    state.userId = id;
    window.localStorage.setItem("userId", id);
  },
  SET_USERNAME: (state: UserState, username: string): void => {
    state.username = username;
  },
  SET_ROLE: (state: UserState, role: Roles): void => {
    state.role = role;
  },
};

export default mutations;
