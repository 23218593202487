import Vue from "vue";
import cacheUtils from "@/utils/cacheUtils";
import { IMessage, ISession } from "@/schemas/ISession";
import { SessionState } from "@/store/modules/session/state.ts";
import { IUser } from "@/schemas/IUser";

const mutations = {
  SET_RANKED_ITEMS: (
    state: SessionState,
    payload: Record<string, any>
  ): void => {
    if (payload.add) {
      Vue.set(state.rankedItems, payload.index, payload.item);
    }
    if (payload.whole) {
      Vue.set(state, "rankedItems", Object.assign({}, payload.items));
    }
    if (payload.remove) {
      Vue.set(state.rankedItems, payload.old_index, {});
    }
    cacheUtils.cache(
      "ranked_items",
      JSON.stringify(Object.values(state.rankedItems))
    );
  },
  SET_ITEMS_TO_BE_RANKED: (
    state: SessionState,
    payload: Record<string, any>
  ): void => {
    if (payload.remove) {
      state.itemsToBeRanked.splice(payload.index, 1);
    }
    if (payload.whole) {
      Vue.set(state, "itemsToBeRanked", payload.items);
    }
    cacheUtils.cache(
      "items_to_be_ranked",
      JSON.stringify(state.itemsToBeRanked)
    );
  },
  SET_CORRELATION_ID: (state: any, correlationId: number): void => {
    state.correlationId = Number(correlationId);
  },
  SET_SESSION: (state: SessionState, payload: ISession): void => {
    state.session = payload;
  },
  SET_SESSIONS: (
    state: SessionState,
    sessions: Array<Record<string, any>>
  ): void => {
    state.sessions = sessions;
  },
  SET_SESSION_MESSAGES(state: SessionState, messages: Array<IMessage>): void {
    Vue.set(state.session, "messages", messages);
  },
  ADD_SESSION_MESSAGE(state: SessionState, message: IMessage): void {
    state.session.messages
      ? state.session.messages.push(message)
      : (state.session.messages = [message]);
  },
  SET_INDIVIDUAL_RESULTS(state: SessionState, results: Array<any>): void {
    Vue.set(state.session, "individual_session_results", results);
  },
  SET_GROUP_RESULTS(state: SessionState, results: Array<any>): void {
    Vue.set(state.session, "group_results", results);
  },
  SET_INDIVIDUAL_RESULT_RANKING(
    state: SessionState,
    payload: Record<string, any>
  ): void {
    if (state.session.results) {
      Vue.set(state.session.results[payload.index], "ranking", payload.ranking);
    }
  },
  SET_SESSION_SCORES(state: SessionState, scores: Record<string, any>[]): void {
    state.sessionScores = scores;
  },
  SET_SESSION_USERS(state: SessionState, users: IUser[]): void {
    state.users = users;
  },
};

export default mutations;
