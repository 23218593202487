import { ActionContext } from "vuex";
import itemRepository from "@/repository/item";
import { responseErrorHandler } from "@/utils";
import { IItem } from "@/schemas/IItem";

interface ItemState {
  items: Record<string, any>[];
}

const state: ItemState = {
  items: [],
};

const item = {
  namespaced: true,
  state,
  getters: {},
  mutations: {
    /**
     * Set state.items with items value
     * @param {ItemState} state
     * @param {Array<Record<string, any>>} items
     */
    SET_ITEMS(state: ItemState, items: Array<Record<string, any>>): void {
      state.items = items;
    },
    /**
     * Remove item identified by itemId from state.items
     * @param {ItemState} state
     * @param {string} itemIndex
     */
    REMOVE_ITEM(state: ItemState, itemIndex: number): void {
      state.items.splice(itemIndex, 1);
    },
    /**
     * Add item to state.items
     * @param {ItemState} state
     * @param {Record<string, any>} item
     */
    ADD_ITEM(state: ItemState, item: Record<string, any>): void {
      state.items.push(item);
    },
    /**
     * Set the items of state.items at payload.index by payload.item
     * @param {ItemState} state
     * @param {Record<string, any>} payload
     */
    UPDATE_ITEM(
      state: ItemState,
      payload: { index: number; item: IItem }
    ): void {
      Object.assign(state.items[payload.index], payload.item);
    },
  },
  actions: {
    /**
     * Fetch items
     * @param {ActionContext<any, any>} context
     */
    fetchItems: (context: ActionContext<any, any>): void => {
      itemRepository
        .getAll(context.rootState.token)
        .then((items) => {
          context.commit("SET_ITEMS", items);
        })
        .catch((error) => responseErrorHandler(error, context));
    },
    /**
     * Delete item identified by payload.itemId
     * @param {ActionContext<any, any>} context
     * @param {Record<string, any>} payload
     */
    deleteItem: (
      context: ActionContext<any, any>,
      payload: { id: string; next?: CallableFunction }
    ): void => {
      itemRepository
        .delete(context.rootState.token, payload.id)
        .then((deleted: boolean) => {
          const itemIndex = context.state.items.findIndex(
            (item: Record<string, any>) => item.id === payload.id
          );
          if (itemIndex) {
            context.commit("REMOVE_ITEM", itemIndex);
          }
          if (payload.next) {
            if (deleted) payload.next();
          }
        })
        .catch((error) => responseErrorHandler(error, context));
    },
    /**
     * Create am item
     * @param {ActionContext<any, any>} context
     * @param {Record<string, any>} payload
     */
    createItem: (
      context: ActionContext<any, any>,
      payload: Record<string, any>
    ): void => {
      itemRepository
        .create(context.rootState.token, payload.item)
        .then((item) => {
          context.commit("ADD_ITEM", item);
        })
        .catch((error) => responseErrorHandler(error, context));
    },
    createItems: (context: ActionContext<any, any>, items: IItem[]): void => {
      itemRepository
        .createMultiple(context.rootState.token, items)
        .then((items) => {
          context.commit("SET_ITEMS", [...context.state.items, ...items]);
        })
        .catch((error) => responseErrorHandler(error, context));
    },
    updateItem: (
      context: ActionContext<any, any>,
      payload: { id: string; item: IItem }
    ): void => {
      itemRepository
        .update(context.rootState.token, payload.id, { ...payload.item })
        .then((updatedItem) => {
          if (context.state.items.length) {
            const updatedIndex = context.state.items.findIndex(
              (item: IItem) => item.id === updatedItem.id
            );
            if (updatedIndex) {
              context.commit("UPDATE_ITEM", {
                index: updatedIndex,
                item: updatedItem,
              });
            }
          }
        })
        .catch((error) => responseErrorHandler(error, context));
    },
  },
};
export default item;
