
import { Vue, Component, Prop } from "vue-property-decorator";
import { assetsUrl } from "@/env";

@Component({ name: "Logo" })
export default class Logo extends Vue {
  @Prop({ required: false, default: 300, type: Number })
  width = 300;
  /**
   * Retrieves image relative path
   * @param slug
   */
  getImageSrc(slug: string): string {
    return `${assetsUrl}/${slug}`;
  }
}
